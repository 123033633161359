.periodicity {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #a6a6a6;
  font-family: Open Sans, "Source Sans Pro";
}

.periodicity span {
  color: #4eb473;
}

.preview-add-to-cart {
  cursor: pointer;
  padding: 0.7rem 1rem;
  overflow: hidden;
  display: flex;
  gap: 10px;
  align-items: center;
  box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
  background-color: #4eb473;
  border-radius: 0.4rem;
  width: fit-content;
}

.preview-add-to-cart-text {
  /* margin: 0.1rem 1.4rem 0rem 0rem; */
  /* text-align: right; */
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}

.preview-add-to-cart-icon {
  width: 0.8rem;
  height: 0.8rem;
  object-fit: contain;
  vertical-align: top;
}

.preview-heading {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 0 1rem;
}

.preview-table-container-bg {
  padding: 1.3rem;
  background-color: #f7f7f7;
  border-radius: 0.4rem;
  /* overflow: auto; */
}

.inner-preview-table{
  background-color: white;
  min-width: 100%;
}

.chat-with-author{

}

.preview-table-content {
  background-color: #ffffff;
  border-radius: 0.4rem;
}

.preview-table-inner {
  width: 100%;
  @media screen and (max-width: 980px) {
    width: 60rem;
  }
}

/* .preview-table-my-revenue{
    width: 100%;
    @media screen and (max-width: 980px) {
        width: 70rem;
    }
} */

.preview-table-headings {
  display: flex;
  padding: 1rem 2rem;
  align-items: center;
}
.preview-table-heading-item {
  font-size: 0.83rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  border-bottom: 1px solid #7b7b7b2c;
  padding-bottom: 1rem;
}

.preview-table-body-container {
  width: 100%;
}

.preview-table-col-4 {
  width: 35%;
  text-align: start;
}
.preview-table-col-3 {
  width: 25%;
  text-align: start;
}

.preview-table-col-1 {
  width: 10%;
  text-align: start;
}
.preview-table-col-2 {
  width: 15%;
  text-align: start;
}
.preview-table-col-3 {
  width: 25%;
  text-align: start;
}

.preview-table-body {
  display: flex;
  padding: 0 2rem;
  align-items: center;
}

.preview-table-body-items {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  border-bottom: 1px solid #7b7b7b2c;
  padding: 2rem 0;
}

.scrollbarinner {
  height: 5px;
  width: 30%;
  background-color: #7b7b7b;
  border-radius: 1rem;
}

.scrollbar {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-template: 1fr / auto 1fr auto;
  padding: 1rem;
  place-items: center;
}

.track-and-thumb {
  display: block;
  width: 100%;
  position: relative;
  height: 16px;
}

.track {
  left: 0;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.thumb {
  position: absolute;
}
