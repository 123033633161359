.reverse-transaction {
  color: #4eb473;
  font-family: Open Sans, "Source Sans Pro";
  cursor: pointer;
}

.approved-payment {
  color: #4eb473;
  font-family: Open Sans, "Source Sans Pro";
  background-color: #e5f2ea;
  padding: 0.3rem 0.75rem;
  border-radius: 0.2rem;
  width: fit-content;
}

.approved-bold {
  font-weight: 600;
  font-size: 0.9rem;
  background-color: #d7e4dc;
}

.payment-dropdown {
  height: 0px;
  overflow: hidden;
  transition: height 0.5s ease;
}

.payment-dropdown.active {
  padding: 2rem 0 0 0;
  height: fit-content;
  transition: height 0.5s ease;
}

.trnsaction-details-heading {
  font-size: 1.5rem;
  font-weight: 600;
  /* line-height: 1.3625; */
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  text-align: center;
}

.transaction-box {
  margin: 1.5rem 0;
  /* margin-right: 1rem; */
  padding: 0.9rem 0;
  width: 100%;
  background-color: rgba(224, 224, 224, 0.5);
  flex-shrink: 0;
}

.side-flex {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: 40%;
}

.payment-transaction {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
}

.transaction-date-Gky {
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  width: 60%;
  transform: translateX(40%);
  @media screen and (max-width: 980px) {
      transform: translateX(20%);
  }
}

.trasanction-item {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.trasanction-item-item {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #4eb473;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.reverse-transaction-btn {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
  background-color: #4eb473;
  border-radius: 0.3rem;
  flex-shrink: 0;
  text-align: center;
  width: 35%;
  padding: 0.6rem 0.3rem;
  @media screen and (max-width: 980px) {
    width: 80%;
  }

}
