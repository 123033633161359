.professional-location-icon{
    width: 1rem;
    height: 1.2rem;
    object-fit: contain;
    vertical-align: top;
}

.scrolldown-icon{
    position: fixed;
    top: 50%;
    left: 15%;
    transform: translateX(-50%);
    z-index: 500;
    cursor: pointer;
    background-color: #f7f7f7;
    border-radius: 50%;
    padding: 0.4rem;
  /* box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964); */
    box-shadow: 0 0rem 0.6rem rgba(0, 0, 0, 0.25);
}

.scrolldown-icon img{
    width: 2rem;
    height: 2rem;
}