.dataloader{
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataloader.active{
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataloader.search{
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.datasvg {
  width: 3.25em;
  transform-origin: center;
  animation: dataRotate4 2s linear infinite;
 }
 
 .dataCircle {
  fill: none;
  stroke: #4eb473;
  stroke-width: 3;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: Dataloader 1.5s ease-in-out infinite;
 }
 
 @keyframes dataRotate4 {
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes Dataloader {
  0% {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
  }
 
  50% {
   stroke-dasharray: 90, 200;
   stroke-dashoffset: -35px;
  }
 
  100% {
   stroke-dashoffset: -125px;
  }
 }
 