.top-breadcrumbs{
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.3333333333;
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    display: flex;
    align-items: baseline;
}

.profile-section-bg{
    background-color: #f7f7f7;
    padding: 2rem;
    @media screen and (max-width: 980px) {
        padding: 1rem;
    }
}