.message-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.message-content {
  height: 85vh;
}

.message-search-profile {
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 0.3rem;
}

.message-search-input {
  width: 100%;
  padding: 0.2rem;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3625;
  /* color: #a6a6a6; */
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  outline: none;
  border: 0;
  background-color: inherit;
}

/* .search-eJ5 {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.3625;
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
  } */
.icon-color-mNh {
  width: 1rem;
  height: 1rem;
}



.message-card-pic-num {
  width: 0.9rem;
  height: 0.9rem;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 600;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4eb473;
  border-radius: 0.7rem;
  position: absolute;
  right: 0%;
  bottom: 10%;
}

.messages-box-wrapper{
  overflow: hidden;
  height: 90%;
}

/* .messages-box-wrapper.active{
  height: 0px;
  overflow: hidden;
} */

.messages-box{
  overflow-y: auto;
  height: 90%;
}




.messages-box::-webkit-scrollbar {
  width: 8px;
  height: 2px;
}


.messages-box::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 5px;
}

.messages-box::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.each-message-profile{
  padding: 0.5rem 0;
  border-bottom: 0.12rem solid #E0E0E0;
  transition: 0.5s ease;
  cursor: pointer;
}

.each-message-profile:hover{
  /* background-color: #4eb473; */
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.25);
  color: white !important;
  padding: 0.4rem;
  border-radius: 0.3rem;
}
.each-message-profile.active{
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.25);
  color: white !important;
  padding: 0.4rem;
  border-radius: 0.3rem;
}


.message-card-pic {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.6rem;
  overflow: hidden;
  position: relative;
}

.message-card-pic-container {
  width: fit-content;
  position: relative;
}

.message-profile{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
}

.message-name{
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, 'Source Sans Pro';
  white-space: nowrap;
  flex-shrink: 0;
}


.message-time{
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #a6a6a6;
  font-family: Open Sans, 'Source Sans Pro';
  white-space: nowrap;
}

.message-snip{
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #454545;
  font-family: Open Sans, 'Source Sans Pro';
  white-space: nowrap;
}