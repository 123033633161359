.notification-container {
  background-color: #f7f7f7;
  padding: 1.5rem 2rem 1.6rem 5rem;
  @media screen and (max-width: 980px) {
    padding: 0.5rem;
  }
}

.notification-box {
  padding: 0.8rem 2rem;
  background-color: #ffffff;
  border-radius: 0.4rem;
  @media screen and (max-width: 980px) {
    padding: 0.8rem 0.7rem;
  }
}
.notification-box-header {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 1.2125;
  color: #d8d8d8;
  font-family: Inter, "Source Sans Pro";
  white-space: nowrap;
}
.notification-active-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3%;
}
 .notification-filters-title {
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
 .notification-filters-item {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.1725;
  color: #7b7b7b;
  /* font-family: Roboto, "Source Sans Pro"; */
  font-family: Open Sans, "Source Sans Pro";
  padding: 0.5rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  border-radius: 3rem;
  /* flex-shrink: 0; */
}

 .notification-filters-item.active {
  background-color: #4eb473;
  color: white;
}












.notification-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f3f3f3;
  }
 .notification-content-message {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.3333333333;
    color: #454545;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    /* flex-shrink: 0; */
  }
 .notification-content-action {
    display: flex;
    align-items: center;
    gap: 20px;
    /* border-bottom: 2px solid #d8d8d8; */
  }
 .sept-02-j9b {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.3333333333;
    color: #454545;
    font-family: Open Sans, 'Source Sans Pro';
  }
.notification-content-cancel{
    width: 1rem;
    height: 1rem;
  }