.admin-signup-type {
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    line-height: 0.5;
    position: relative;
  }

  .admin-individual{
    width: 50%;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    padding-bottom: 5px;
  }
  
  .admin-individual.active {
    position: relative;
    color: #4eb473;
  }
  
  .admin-individual::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0%;
    height: 2px;
    width: 0%;
    overflow: hidden;
    transition: width 0.6s ease;
  }
  .admin-individual.active::before {
    content: "";
    position: absolute;
    top: 130%;
    left: 0%;
    height: 2px;
    width: 100%;
    background-color: #4eb473;
    transition: width 0.6s ease;
  }









  .admin-corperate{
    width: 50%;
    text-align: center;
    font-weight: 600;
    padding-bottom: 5px;
    cursor: pointer;
  }
  
  .admin-corperate.active {
    position: relative;
    color: #4eb473;
  }
  
  .admin-corperate::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0%;
    height: 2px;
    width: 0%;
    overflow: hidden;
    transition: width 0.6s ease;
  }
  .admin-corperate.active::before {
    content: "";
    position: absolute;
    top: 130%;
    left: 0%;
    height: 2px;
    width: 100%;
    background-color: #4eb473;
    transition: width 0.6s ease;
  }