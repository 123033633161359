.loader-spinner {
  width: 2rem;
  height: 2rem;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-color: #dbdcef #0000;
  animation: loader-spinner-e04l1k 1s infinite linear;
  @media screen and (max-width: 980px) {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.loader-spinner::before,
.loader-spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
}

.loader-spinner::before {
  border-color: #4eb473 #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.loader-spinner::after {
  margin: 8.9px;
}

@keyframes loader-spinner-e04l1k {
  100% {
    transform: rotate(1turn);
  }
}
