

.email-card {
  overflow: hidden;
  position: relative;
  text-align: left;
  border-radius: 0.5rem;
  max-width: 300px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background-color: #fff;
}

.email-dismiss {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: black;
  border: 2px solid #D1D5DB;
  font-size: 1rem;
  font-weight: 300;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  transition: .3s ease;
}

.email-dismiss:hover {
  background-color: #ee0d0d;
  border: 2px solid #ee0d0d;
  color: #fff;
}

.email-header {
  padding: 1.25rem 1rem 1rem 1rem;
}

.email-image {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #e2feee;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  animation: animate .6s linear alternate-reverse infinite;
  transition: .6s ease;
}
.email-image-failed {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffd1c3;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  animation: animate .6s linear alternate-reverse infinite;
  transition: .6s ease;
}

.email-image svg {
  color: #0afa2a;
  width: 2rem;
  height: 2rem;
}
.email-image-failed svg {
  color: #ffffff !important;
  width: 1.5rem;
  height: 1.5rem;
}

.email-content {
  margin-top: 0.75rem;
  text-align: center;
}

.email-title {
  color: #066e29;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.email-title-failed {
  color: #b90c18;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.email-message {
  margin-top: 0.5rem;
  color: #595b5f;
  font-size: 0.8rem;
  line-height: 1.25rem;
}

.email-actions {
  margin: 0.75rem 1rem;
}

.email-history {
  display: inline-flex;
  padding: 0.5rem 1rem;
  background-color: #1aa06d;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.email-history-failed {
  display: inline-flex;
  padding: 0.5rem 1rem;
  background-color: #b90c18;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.email-track {
  display: inline-flex;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  color: #242525;
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #D1D5DB;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

@keyframes animate {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.09);
  }
}