.adminsearchhistory {
  width: 100%;
  border: 0.1rem solid #e3e3e3;
  border-radius: 0.3rem;
  height: 100%;
  padding: 0 1rem 1rem 1rem;
  overflow-x: auto !important;
}

.adminsearchhistorytable{
  width: 100%;
  height: 100%;
  @media screen and (max-width: 980px) {
    width: 50rem;
  }
}

.admin-search-history-heading {
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
  border-bottom: 0.1rem solid #e3e3e3;
}
.admin-search-history-body {
  display: flex;
  align-items: center;
  padding: 1.1rem 0;
  border-bottom: 0.1rem solid #e3e3e3;
}

.admin-search-items {
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  width: 40%;
}
.admin-search-user {
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  width: 30%;
}
.admin-search-count {
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  width: 30%;
}
.admin-search-user-body {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  width: 40%;
}
.admin-search-count-body {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  width: 30%;
}

.admin-search-body {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  width: 30%;
}

.admin-search-body-content {
  position: relative;
  width: fit-content;
}

.admincountpic {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
}

.admin-search-body-number {
  width: 0.7rem;
  height: 0.7rem;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.6rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #17b24e;
  border-radius: 50%;
}
