.upload-container-box {
  padding: 0 2rem 0 7.5rem;
  margin-bottom: 5rem;
  @media screen and (max-width: 980px) {
    padding: 0 1rem;
  }
}
.textarea-input {
  resize: none;
  height: 8rem;
}

.textarea__field {
  width: 100%;
  /* height: 100%; */
  border: 0;
  outline: 0;
  font-size: 16px;
  /* color: var(--text); */
  padding: 0.85rem 1rem;
  padding-right: 32px;
  background-color: white;
}

.textarea__field::placeholder {
  color: white;
  font-size: 0.5rem;
  transition: color 0.3s ease;
}

.textarea__field:placeholder-shown ~ .textarea__label {
  /* position: absolute; */
  font-size: 15px;
  cursor: text;
  /* top: 15%; */
  z-index: 10;
  transition: top 0.5s ease;
}

.textarea__field > option {
  color: #d8d8d8;
}

/* label, */
.textarea__field:focus ~ .textarea__label {
  position: absolute;
  top: -10%;
  transition: top 0.5s ease;
  display: block;
  left: 3%;
  background-color: white;
  width: fit-content;
  font-size: 0.8rem;
  color: #17b24e;
  transition: 0.3s;
  padding: 0 0.2rem 0.3rem 0.5rem;
}

label {
  color: #a7a7a7;
}

/* .textarea__field:focus ~ .input__wrapper{
    border: solid 0.15rem #17b24e;
} */

.textarea__field:focus {
  color: #17b24e;
  background-color: white;
}

.textarea__label {
  /* display: none; */
  transition: color 0.3s ease;
  font-size: 15px;
  position: absolute;
  top: 10%;
  transition: top 0.5s ease;
  display: block;
  left: 3%;
  /* background-color: white;
  width: fit-content;
  font-size: 0.8rem;
  color: #17b24e;
  transition: 0.3s; */
  padding: 0 0.2rem 0.3rem 0.5rem;
}
.upload__label {
  /* display: none; */
  transition: color 0.3s ease;
  font-size: 13px;
  position: absolute;
  top: -25%;
  transition: top 0.5s ease;
  display: block;
  left: 3%;
  background-color: white;
  height: fit-content;
  /* 
  width: fit-content;
  font-size: 0.8rem;
  color: #17b24e;
  transition: 0.3s; */
  margin: 0;
  padding: 0 0.2rem 0 0.5rem;
}
/* .textarea__field:focus{
    border-bottom: 2px solid var(--primary);
} */

.textarea__field:focus .input__icon {
  color: #17b24e;
}

.keywords-box {
  overflow: auto;
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  padding: 0.35rem 0;
  cursor: grabbing;
}

.keyword-items {
  background-color: #e0e0e0;
  font-size: 0.8rem;
  font-family: Open Sans, "Source Sans Pro";
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #adadad;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  border-radius: 0.8rem;
}

.keyword-wrapper {
  position: relative;
  border: solid 0.1rem #d8d8d8;
  border-radius: 0.3rem;
  height: fit-content;
  padding: 0.3rem 0.5rem;
}

.keyword__label {
  position: absolute;
  background-color: white;
  top: -20%;
  left: 5%;
  color: #a7a7a7;
  font-size: 0.75rem;
  width: fit-content;
  height: fit-content;
}

.keywords-box::-webkit-scrollbar {
  height: 5px;
}

.keywords-box::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 5px;
}

.keywords-box::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.add-co-author {
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
}

.author-pic{
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 50%;
}


.add-co-author-btn{
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #4eb473;
  font-family: Open Sans, 'Source Sans Pro';
}

.update-article{
  cursor: pointer;
  overflow: hidden;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #ffffff;
  font-family: Open Sans, 'Source Sans Pro';
  white-space: nowrap;
  display: flex;
  padding: 0.7rem 0;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.2rem 0.4rem rgba(23, 178, 78, 0.2300000042);
  background-color: #4eb473;
  border-radius: 0.35rem;
}