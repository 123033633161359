.profile-bg {
  background-color: white;
  border-radius: 1rem;
  padding: 1.5rem;
  position: relative;
}

.group-157-QpZ {
  padding: 2.4rem 4.6rem;
  /* width: 128.4rem; */
  /* height: 41.2rem; */
  /* position: absolute; */
  /* left: 7.8rem; */
  /* top: 14.9rem; */
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.4rem;
}
.group-157-QpZ .auto-group-zzft-KAq {
  /* margin-right: 9.7rem; */
  /* width: 30.2rem; */
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* flex-shrink: 0; */
}
.profile-pic-img {
  width: 4rem;
  height: 4rem;
  /* background-size: 100% 100%; */
  border-radius: 50%;
  /* overflow: hidden; */
  flex-shrink: 0;
}
.group-157-QpZ .auto-group-zzft-KAq .group-124-eyo .image-2-MdK {
  /* width: 5.5rem; */
  /* height: 5.5rem; */
  object-fit: cover;
  /* vertical-align: top; */
}
.group-157-QpZ .auto-group-zzft-KAq .primary-button-TwF {
  width: 100%;
  /* height: 4.4rem; */
  overflow: hidden;
  /* position: relative; */
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
  background-color: #4eb473;
  border-radius: 0.4rem;
  /* flex-shrink: 0; */
}
.group-157-QpZ .auto-group-su1j-vZw {
  margin-bottom: 0.75rem;
  /* width: 79.3rem; */
  /* height: 35.65rem; */
  /* position: relative; */
  /* flex-shrink: 0; */
}
.group-157-QpZ .auto-group-su1j-vZw .frame-356-rTb {
  /* width: 79.25rem; */
  /* height: 29.25rem; */
  /* position: absolute; */
  /* left: 0; */
  /* top: 6.4rem; */
  align-items: center;
  display: flex;
  flex-direction: column;
}
.group-157-QpZ .auto-group-su1j-vZw .frame-356-rTb .frame-354-nc9 {
  /* width: 100%; */
  /* height: 5.7rem; */
  display: flex;
  align-items: center;
  /* flex-shrink: 0; */
}
.profile-name {
  font-size: 2rem;
  height: 100%;
  font-weight: 800;
  line-height: 1.2125;
  color: #a6a6a6;
  font-family: Inter, "Source Sans Pro";
  white-space: nowrap;
  @media screen and (max-width: 980px) {
    font-size: 1.2rem;
  }
  /* flex-shrink: 0; */
}
.profile-verified{
  color: #a6a6a6;
  background-color: #a6a6a639;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.45rem;
  padding: 0.25rem 0.55rem;
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.3625;
}

.profile-verified.active {
  padding: 0.25rem 0.55rem;
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #4eb473;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0ffeb;
  border-radius: 0.45rem;
  /* flex-shrink: 0; */
}


.group-157-QpZ .auto-group-su1j-vZw .frame-356-rTb .auto-group-gnbk-iu7 {
  padding: 1.6rem 0.125rem 0rem 0.125rem;
  /* width: 100%; */
  align-items: center;
  display: flex;
  flex-direction: column;
  /* flex-shrink: 0; */
}
.group-157-QpZ
  .auto-group-su1j-vZw
  .frame-356-rTb
  .auto-group-gnbk-iu7
  .frame-246-FPF {
  margin-bottom: 1.5rem;
  /* width: 100%; */
  height: 2.1rem;
  display: flex;
  align-items: center;
  /* flex-shrink: 0; */
}
.profile-profession {
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.profile-location-container {
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  align-items: center;
  justify-content: center;
  /* flex-shrink: 0; */
}
.profile-location-icon {
  margin-right: 0.8rem;
  width: 1.1rem;
  height: 1.5rem;
  object-fit: contain;
  vertical-align: top;
  /* flex-shrink: 0; */
}
.profile-location-name {
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.group-157-QpZ
  .auto-group-su1j-vZw
  .frame-356-rTb
  .auto-group-gnbk-iu7
  .frame-355-iAM {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  /* flex-shrink: 0; */
}
.profile-bio {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 2.6666666667;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  /* flex-shrink: 0; */
  text-align: justify;
}
.profile-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
}
.profile-number {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.phone-number-title {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.phone-number-digit {
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.profile-email {
  display: flex;
  align-items: center;
  /* flex-shrink: 0; */
  gap: 1rem;
}
.profile-email-title {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.profile-email-content {
  /* text-align: right; */
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
  @media screen and (max-width: 980px) {
    font-size: 0.85rem;
    font-weight: 700;
  }
}
.primary-button-with-icon {
  padding: 0rem 1.5rem 0rem 1.5rem;
  width: fit-content;
  gap: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  color: white;
  display: flex;
  align-items: center;
  box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
  background-color: #4eb473;
  border-radius: 0.4rem;
}
.profile-btn-text {
  /* margin: 0.1rem 1.4rem 0rem 0rem; */
  font-size: 1rem;
  margin-top: 0.55rem;
  font-weight: 600;
  line-height: 1.3625;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}

.profile-btn-content {
  gap: 2rem;
}

.profile-btn-icon-container {
  /* width: 2rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-btn-icon {
  width: 1.3rem;
  height: 1.3rem;
  margin-top: 0.55rem;
  /* object-fit: contain;
    vertical-align: top;
    flex-shrink: 0; */
}
.profile-secondary-button {
  width: fit-content;
  overflow: hidden;
  padding: 0.8rem 2rem 0.8rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 0.4rem;
}
.profile-switch-acct {
  width: 20%;
  position: absolute;
  right: 30%;
  top: 15%;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.150000006);
  background-color: #ffffff;
  border-radius: 0.4rem;
  padding: 1rem;
}

.profile-edit-btn {
  width: fit-content;
  position: absolute;
  right: 5%;
  top: 15%;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.150000006);
  background-color: #ffffff;
  border-radius: 0.4rem;
  padding: 1rem;
}

@media screen and (max-width: 767px) {
  .profile-switch-acct {
    width: 70%;
  }
}

@media screen and (max-width: 980px) {
  .profile-switch-acct {
    width: 50%;
  }
}
.profile-switch-acct .profile-switch-content {
  display: flex;
  flex-direction: column;
}
.profile-switch-acct .profile-switch-content .profile-custom {
  /* margin: 0rem 0rem 1.2rem 1.1rem; */
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}

.profile-custom.active {
  color: #4eb473 !important;
}

.profile-switch-acct .profile-switch-content .profile-corporate-container {
  /* margin-bottom: 0.7rem; */
  width: 100%;
  display: flex;

  /* flex-shrink: 0; */
  position: relative;
}
.profile-switch-acct
  .profile-switch-content
  .profile-corporate-container
  .profile-corporate {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #a6a6a6;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}

.profile-corporate::before {
  content: "";
  position: absolute;
  top: 30%;
  right: 0;
  width: 50%;
  height: 0.1rem;
  background-color: #e0e0e0;
  /* flex-shrink: 0; */
}
/* .profile-corporate-line {
    width: 50%;
    height: 0.1rem;
    background-color: #e0e0e0;
    flex-shrink: 0;
  } */
.profile-switch-acct .profile-switch-content .corp2413-2AM {
  margin: 0rem 0rem 1.2rem 1.1rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.profile-switch-acct .profile-switch-content .corp2413-YPb {
  margin: 0rem 0rem 1.2rem 1.1rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.profile-switch-acct .profile-switch-content .auto-group-ndiz-sgm {
  margin-bottom: 0.7rem;
  /* width: 100%; */
  display: flex;
  align-items: center;
  /* flex-shrink: 0; */
}
.profile-switch-acct
  .profile-switch-content
  .auto-group-ndiz-sgm
  .subcorporate-c8Z {
  /* margin-right: 0.8rem; */
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #a6a6a6;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.line-38-jiy {
  width: 5rem;
  height: 0.1rem;
  background-color: #e0e0e0;
  /* flex-shrink: 0; */
}
.profile-switch-acct .profile-switch-content .corp2413-Lim {
  margin: 0rem 0rem 1.2rem 1.1rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.profile-switch-acct .profile-switch-content .corp2413-48y {
  /* margin-left: 1.1rem; */
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.frame-58-vgy {
  /* width: 52.1rem; */
  /* height: 2.1rem; */
  /* position: absolute; */
  /* left: 26.5rem; */
  /* top: 3.4rem; */
  display: flex;
  column-gap: 5.8rem;
  align-items: center;
}

.profileimage {
  width: 40vh;
  height: 40vh;
  border-radius: 50%;
  @media screen and (max-width: 980px) {
    width: 25vh;
    height: 25vh;
  }
}

.cameraicon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  @media screen and (max-width: 980px) {
    width: 3rem;
    height: 3rem;
    right: 10%;
    bottom: 5%;
  }
}

.profile-pic-col{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.changeprofilepic {
  font-size: 0.9rem;
  padding: 0.5rem 2rem;
  width: 80%;
  font-weight: 600;
  line-height: 1.3625;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
  background-color: #4eb473;
  border-radius: 0.3rem;
}
