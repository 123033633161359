.description-main{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.description-container {
    /* width: fit-content;
    height: fit-content; */
    padding: 1rem;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.25);
    background-color: white;
  }
 .description-box {
    /* padding: 1.6rem 4.3rem 2.4rem 4.4rem; */
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 1rem;
  }
 .description-heading {
    text-align: center;
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.2125;
    color: #454545;
    font-family: Inter, 'Source Sans Pro';
    white-space: nowrap;
    flex-shrink: 0;
  }
 .first-name-857 {
    flex-shrink: 0;
  }
.rectangle-19-Tt5 {
    border: solid 0.2rem #d8d8d8;
    background-color: #ffffff;
    border-radius: 0.4rem;
  }
   .the-quick-brown-fox-jumps-over-a-lazy-dog-mNy {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.3333333333;
    color: #7b7b7b;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
  }
.frame-24-fDT {
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1.3625;
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
  }
 .description-text {
    padding: 1rem 0;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1.8666666667;
    color: #454545;
    font-family: Open Sans, 'Source Sans Pro';
    /* flex-shrink: 0; */
  }
 .auto-group-neav-Cch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
  }
 .go-back-btn {
    padding: 0.5rem 0;
    width: 45%;
    overflow: hidden;
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 1.3625;
    color: #ffffff;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.3rem 1rem rgba(245, 95, 68, 0.25);
    background-color: #f55f44;
    border-radius: 0.3rem;
    flex-shrink: 0;
  }
 .alright-save-btn {
    width: 45%;
    padding: 0.5rem 0;
    cursor: pointer;
    overflow: hidden;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 1.3625;
    color: #ffffff;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.2rem 0.4rem rgba(10, 131, 221, 0.2899999917);
    background-color: #4eb473;
    border-radius: 0.3rem;
    flex-shrink: 0;
  }


  .description-input__wrapper{
    position: relative;
    border: solid 0.1rem #d8d8d8;
    border-radius: 0.3rem;
    height: fit-content;
}


.description-textarea-input {
    resize: none;
    height: 10rem;
    width: 100%;
    border-radius: 0.3rem;
  }
  
  .description-textarea__field {
    width: 100%;
    /* height: 100%; */
    border: 0;
    outline: 0;
    font-size: 16px;
    /* color: var(--text); */
    padding: 0.85rem 1rem;
    padding-right: 32px;
    background-color: white;
    border-radius: 0.3rem;
  }
  
  .description-textarea__field::placeholder {
    color: white;
    font-size: 0.5rem;
    transition: color 0.3s ease;
  }
  
  .description-textarea__field:placeholder-shown ~ .textarea__label {
    /* position: absolute; */
    font-size: 15px;
    cursor: text;
    /* top: 15%; */
    z-index: 10;
    transition: top 0.5s ease;
  }
  
  .description-textarea__field > option {
    color: #d8d8d8;
  }
  
  /* label, */
  .textarea__label {
    /* display: none; */
    transition: color 0.3s ease;
    font-size: 15px;
    position: absolute;
    top: 10%;
    transition: top 0.5s ease;
    display: block;
    left: 3%;
    /* background-color: white;
    width: fit-content;
    font-size: 0.8rem;
    color: #17b24e;
    transition: 0.3s; */
    padding: 0 0.2rem 0.3rem 0.5rem;
  }

  label {
    color: #a7a7a7;
  }

  .textarea__label.focused {
    position: absolute;
    top: -10%;
    transition: top 0.5s ease;
    display: block;
    left: 3%;
    background-color: white;
    width: fit-content;
    font-size: 0.8rem;
    color: #17b24e;
    transition: 0.3s;
    padding: 0 0.2rem 0.3rem 0.5rem;
  }
  

  /* .description-textarea__field:focus ~ .input__wrapper{
      border: solid 0.15rem #17b24e;
  } */
  
  .description-textarea__field:focus {
    color: #17b24e;
    background-color: white;
  }
  

  .upload__label {
    /* display: none; */
    transition: color 0.3s ease;
    font-size: 13px;
    position: absolute;
    top: -25%;
    transition: top 0.5s ease;
    display: block;
    left: 3%;
    background-color: white;
    height: fit-content;
    /* 
    width: fit-content;
    font-size: 0.8rem;
    color: #17b24e;
    transition: 0.3s; */
    margin: 0;
    padding: 0 0.2rem 0 0.5rem;
  }
  /* .description-textarea__field:focus{
      border-bottom: 2px solid var(--primary);
  } */
  
  .description-textarea__field:focus ~ .input__icon {
    color: #17b24e;
  }
  

  /* .input__label,
.input__field:focus ~ .input__label {
  position: absolute;
  top: -20%;
  transition: top 0.5s ease;
  display: block;
  left: 3%;
  background-color: white;
  width: fit-content;
  font-size: 0.75rem;
  color: #17b24e;
  transition: 0.3s;
  padding: 0 0.2rem 0.3rem 0.5rem;
} */