.empty-pending-friends{
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.request-btn{
    cursor: pointer;
}