.range-container-overlay {
    height: 0;
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.029);
    /* backdrop-range: blur(1px); */
    transition: height 0.4s ease;
  }
  
  .range-container-overlay.open {
    height: 100vh;
    transition: height 0.4s ease;
  }
  
  .range-container {
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.25);
    background-color: white;
    border-radius: 0.3rem;
    padding: 0.8rem 1.5rem;
  }
  
  .range-sub-heading{
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1.3625;
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
  }
  
  .range-heading {
    /* text-align: center; */
    margin-right: 30%;
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 1.2125;
    color: #454545;
    font-family: Inter, "Source Sans Pro";
  }
  
  .range-content{
      /* display: flex; */
      position: relative;
      /* align-items: center; */
      top: 10%;
  }
  
  .range-cancel-btn-container{
      width: 2rem;
      height: 2rem;
  }
  
  .range-switch{
    position: relative;
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
    font-size: 0.9rem;
    font-weight: 600;
  }
  
  .range-switch.active{
    color: #4eb473;
  }
  
  
  .range-switch::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    transition: width 0.4s ease;
  }
  
  .range-switch.active::before{
    width: 100%;
    background-color: #4eb473;
    transition: width 0.4s ease;
  }
  
  .data-range-border{
    padding: 0.7rem 0.8rem;
    border: solid 0.1rem #d8d8d8;
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 0.3rem;
    overflow: auto;
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    cursor: grab;
    user-select: none;
  }
  
  .border-container{
    position: relative;
  }
  
  .data-range-border::-webkit-scrollbar {
    width: 10px;
    height: 2px;
  }
  
  .data-range-border:active {
    cursor: grabbing;
  }
  
  .data-range-border::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    border-radius: 5px;
  }
  
  .data-range-border::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .data-range-items{
    font-size: 0.9rem;
    width: 20%;
    font-weight: 600;
    line-height: 1.3625;
    color: #7b7b7b;
    font-family: Open Sans, 'Source Sans Pro';
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    flex-shrink: 0;
    background-color: #f3f3f3;
    border-radius: 1rem;
    padding: 0.4rem 0.5rem;
  }
  
  .remove-btn-container{
    width: 0.5rem;
    height: 0.5rem;
  }
  
  
  .line-part{
    display: flex;
    justify-content: center;
    gap: 0.3rem;
    align-items: center;
    flex-shrink: 0;
  }
  
  .lineA{
    width: 45%;
    height: 0.125rem;
    background-color: #e0e0e0;
    flex-shrink: 0;
  }
  
  .or-text{
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.3625;
    color: #e0e0e0;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    flex-shrink: 0;
    margin-top: 10px;
  }
  
  .range-year-label{
    /* width: 25%; */
    padding: 0.3rem;
    position: absolute;
    left: 3%;
    top: -25%;
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1.3625;
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
  }
  
  .data-range-input{
    position: relative;
      border: solid 0.15rem #d8d8d8;
      border-radius: 0.3rem;
      height: fit-content;
  }
  
  .data-input-field{
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    font-size: 16px;
    /* color: var(--text); */
    padding: 0.85rem 1rem;
    padding-right: 32px;
    background-color: white;
  }
  
  .data-input-field::placeholder{
    color: white;
    font-size: 0.5rem;
    transition: color 0.3s ease;
  }
  
  .data-input-field:placeholder-shown~.data-input-label{
    font-size: 15px;
    cursor: text;
    top: 20%;
    transition: top 0.5s ease;
  }
  
  .data-input-label,
  .data-input-field:focus~.data-input-label{
      position: absolute;
      top: -20%;
      transition: top 0.5s ease;
      display: block;
      left: 3%;
      background-color: white;
      width: fit-content;
      font-size: 0.8rem;
      color: #17b24e;
      transition: 0.3s;
      padding: 0 0.2rem 0.3rem 0.5rem;
  }
  
  .data-input-label{
      color: #a7a7a7;
      font-size: 0.8rem;
  }
  
  /* .data-input-field:focus ~ .input__wrapper{
      border: solid 0.15rem #17b24e;
  } */
  
  
  .data-input-field:focus{
      color: #17b24e;
      background-color: white;
  }
  .data-input-label{
      /* display: none; */
      transition: color 0.3s ease;
      font-size: 15px;
  }
  
  
  .range-content{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .year-range{
    font-size: 0.85rem;
    color: #17b24e;
  }
  
  
  .select-all-year{
    display: flex;
    gap: 10px;
  }
  
  .all-year-check{
    color: #a7a7a7;
  }
  
  .all-year-check:checked{
    color: #17b24e;
  }
  
  .all-year-check:checked .select-text{
    color: #17b24e;
  }
  
  .select-text{
    font-size: 0.9rem;
    color: #a7a7a7;
  }