.success-notifications-container {
    width: 320px;
    height: auto;
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .success-flex {
    display: flex;
  }
  
  .success-flex-shrink-0 {
    flex-shrink: 0;
  }
  
  .success-check {
    padding: 1rem;
    border-radius: 0.375rem;
    background-color: rgb(240 253 244);
  }
  
  .succes-svg {
    color: rgb(74 222 128);
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .success-prompt-wrap {
    margin-left: 0.75rem;
  }
  
  .success-prompt-heading {
    font-weight: bold;
    color: rgb(22 101 52);
  }
  
  .success-prompt-prompt {
    margin-top: 0.5rem;
    color: rgb(21 128 61);
  }
  
  .success-button-container {
    display: flex;
    margin-top: 0.875rem;
    margin-bottom: -0.375rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  
  .success-button-main {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: #ECFDF5;
    color: rgb(22 101 52);
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: bold;
    border-radius: 0.375rem;
    border: none
  }
  
  .success-button-main:hover {
    background-color: #D1FAE5;
  }
  
  .success-button-secondary {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.75rem;
    background-color: #ECFDF5;
    color: #065F46;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.375rem;
    border: none;
  }
  