.table-col-A{
    width: 16.3%;
}

.table-col-B{
    width: 12.1%;
}

.dropdown-revenue{
    background-color: #f7f7f7;
    padding: 1rem 0;
}

.dropdown-card{
    background-color: white;
    border-radius: 0.4rem;
}