
  .top-title{
    display: flex;
    justify-content: space-between;
    /* align-items: flex-start; */
    flex-shrink: 0;
    width: 60%;
  }


  .remove-range-btn{
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    vertical-align: top;
    flex-shrink: 0; 
  }

  .top-title-text{
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.2125;
    color: #454545;
    font-family: Inter, 'Source Sans Pro';
    white-space: nowrap;
    flex-shrink: 0; 
  }

  .filter-by{
    position: absolute;
    z-index: 100;
    top: 0;
    background-color: white;
  }

  .filter-aside::before{
    content: "";
    height: 100%;
    width: 3px;
    background-color: #4eb473;
    border-radius: 0.35rem;
    position: absolute;
    right: -20%;
    top: 0;
    bottom: 0;
  }


  .filter-aside-item{
    font-size: 0.95rem;
    font-weight: 600;
    line-height: 1.3625;
    color: #A7A7A7;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    flex-shrink: 0;
    padding: 1rem 0;
    cursor: pointer;
  }

  .filter-aside-item.active{
    color: #4eb473;
  }

  /* .filter-btn-section{
    position: absolute;
    bottom: 0;
    right: 0;
  } */

  .filter-cancel-btn{
    padding: 0.65rem 1.5rem;
    border-radius: 0.35rem;
    font-size: 0.95rem;
    font-weight: 600;
    line-height: 1.3625;
    color: #7b7b7b;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    border-radius: 0.4rem;
    cursor: pointer;
  }
  .filter-ok-btn{
    padding: 0.65rem 1.5rem;
    border-radius: 0.35rem;
    font-size: 0.95rem;
    font-weight: 600;
    line-height: 1.3625;
    color: white;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
    background-color: #4eb473;
    border-radius: 0.4rem;
    cursor: pointer;
  }

