:root {
  --con-primary: #2ecc71;    /* Green */
  --con-secondary: #8b5cf6;  /* Purple */
  --con-accent: #27ae60;     /* Dark Green */
  --con-light: #ddd6fe;     /* Light Purple */
  --con-dark: #2d3748;      /* Dark Text */
  --con-text: #2d3748;      /* Text Color */
  --con-gradient-start: #f0fff4;   /* Light Green */
  --con-gradient-end: #ede9fe;     /* Light Purple */
  --con-card-background: #ffffff;
}

.con-container {
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

/* Navbar Styles */
.con-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  position: fixed;
  width: 90%;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.con-logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--con-dark);
}

.con-highlight {
  background: linear-gradient(135deg, var(--con-primary), var(--con-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.con-nav-links {
  display: flex;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.con-nav-links a {
  text-decoration: none;
  color: var(--con-text);
  font-weight: 500;
  transition: color 0.3s;
}

.con-nav-links a:hover {
  color: var(--con-primary);
}

/* Hero Section Layout */
.con-hero {
  min-height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, var(--con-gradient-start), var(--con-gradient-end));
  padding-top: 1rem;
  text-align: center;
  margin-top: 0;
}

.con-hero-content {
  max-width: 50%;  /* Limit content width */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  animation: fadeIn 1s ease-out;
}

.con-hero-image {
  width: 45%;
  height: auto;
  max-height: 600px;
  animation: floatAnimation 3s ease-in-out infinite;
}

/* Add floating animation */
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.con-hero-title {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.con-hero-title h1 {
  font-size: 3.2rem;
  line-height: 1.2;
  margin: 0;
  background: linear-gradient(135deg, var(--con-primary), var(--con-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.con-hero-subtitle {
  font-size: 1.3rem;
  color: var(--con-text);
  line-height: 1.4;
  max-width: 900px;
  margin: 0 auto;
}

.con-hero-description {
  font-size: 1.1rem;
  color: var(--con-text);
  opacity: 0.9;
  max-width: 800px;
  margin: 0 auto;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .con-hero {
    min-height: 70vh;
    padding-top: 4rem;
  }

  .con-hero-content {
    max-width: 100%;
    order: 1;
  }

  .con-hero-image {
    width: 80%;
    margin-top: 2rem;
    order: 2;
  }

  .con-hero-title h1 {
    font-size: 2.3rem;
  }

  .con-hero-subtitle {
    font-size: 1.1rem;
  }

  .con-hero-description {
    font-size: 1rem;
  }
}

/* Hero Section CTA Button - Adjusted */
.con-hero .con-cta-button {
  padding: 1rem 2.5rem;  /* Reduced padding */
  font-size: 1.1rem;     /* Slightly smaller font */
  margin: 0 auto;        /* Center the button */
  max-width: fit-content; /* Make button width fit content */
  background: linear-gradient(135deg, var(--con-primary), var(--con-secondary));
  color: white;
  border: none;
  border-radius: 30px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.75rem;  /* Reduced gap between text and arrow */
}

.con-hero .con-cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(46, 204, 113, 0.3);
}

.con-hero .con-button-icon {
  font-size: 1.1rem;  /* Match font size with text */
  transition: transform 0.3s ease;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .con-hero .con-cta-button {
    padding: 0.875rem 2rem;  /* Even more compact on mobile */
    font-size: 1rem;
  }
}

/* Services Section */
.con-services {
  padding: 6rem 5%;
  background-color: white;
}

.con-services h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: var(--con-dark);
}

.con-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.con-service-card {
  padding: 2rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
}

.con-service-card:hover {
  transform: translateY(-5px);
}

.con-service-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, var(--con-gradient-start), var(--con-gradient-end));
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 1.5rem;
}

/* Process Section */
.con-process {
  padding: 6rem 5%;
  background: linear-gradient(135deg, var(--con-gradient-start), var(--con-gradient-end));
}

.con-process h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: var(--con-dark);
}

.con-process-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.con-step {
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.con-step-number {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, var(--con-primary), var(--con-secondary));
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 auto 1rem;
}

/* Contact Section - Redesigned */
.con-contact {
  padding: 6rem 5%;
  background: linear-gradient(135deg, var(--con-gradient-start), var(--con-gradient-end));
  position: relative;
  overflow: hidden;
}

.con-contact::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><circle cx="2" cy="2" r="1" fill="%232ecc71" opacity="0.1"/></svg>') repeat;
  pointer-events: none;
}

.con-contact-wrapper {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.con-contact-content {
  background: white;
  border-radius: 30px;
  padding: 4rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  overflow: hidden;
}

.con-contact-text {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.con-contact-text h2 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, var(--con-primary), var(--con-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
}

.con-contact-text p {
  font-size: 1.25rem;
  color: var(--con-text);
  line-height: 1.6;
  opacity: 0.9;
}

.con-contact-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.con-cta-button {
  background: linear-gradient(135deg, var(--con-primary), var(--con-secondary));
  color: white;
  padding: 1.25rem 3rem;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.con-button-icon {
  transition: transform 0.3s ease;
}

.con-cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 25px rgba(46, 204, 113, 0.3);
}

.con-cta-button:hover .con-button-icon {
  transform: translateX(5px);
}

.con-contact-divider {
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--con-text);
  opacity: 0.5;
}

.con-contact-divider::before,
.con-contact-divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background: currentColor;
}

.con-contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.con-contact-method {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.con-contact-icon {
  font-size: 1.25rem;
}

.con-contact-method a {
  color: var(--con-primary);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.con-contact-method a:hover {
  color: var(--con-secondary);
}

/* Responsive Design Updates */
@media (max-width: 768px) {
  .con-contact-content {
    padding: 2rem;
    gap: 2rem;
  }

  .con-contact-text h2 {
    font-size: 2.25rem;
  }

  .con-contact-text p {
    font-size: 1.1rem;
  }

  .con-cta-button {
    padding: 1rem 2rem;
    font-size: 1.1rem;
    width: 100%;
    justify-content: center;
  }

  .con-contact-info {
    width: 100%;
  }
}

/* Footer */
.con-footer {
  background: var(--con-dark);
  color: white;
  padding: 4rem 5% 2rem;
}

.con-footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.con-footer-section h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.con-social-links {
  display: flex;
  gap: 1rem;
}

.con-social-links a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.con-social-links a:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .con-nav-links {
    display: none;
  }

  .con-hero-content h1 {
    font-size: 2.5rem;
  }

  .con-hero {
    padding-top: 4rem;
    min-height: 80vh;
  }

  .con-services-grid,
  .con-process-steps {
    grid-template-columns: 1fr;
  }

  .con-service-card,
  .con-step {
    padding: 1.5rem;
  }

  .con-footer-content {
    text-align: center;
  }

  .con-social-links {
    justify-content: center;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.con-hero-content,
.con-service-card,
.con-step,
.con-contact-content {
  animation: fadeIn 1s ease-out;
}

/* Testimonials Section */
.con-testimonials {
  padding: 6rem 5%;
  background: linear-gradient(135deg, var(--con-gradient-start), var(--con-gradient-end));
}

.con-testimonials h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: var(--con-dark);
}

.con-testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.con-testimonial-card {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.con-testimonial-card:hover {
  transform: translateY(-5px);
}

.con-testimonial-card > p {
  font-size: 1.1rem;
  color: var(--con-text);
  font-style: italic;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.con-testimonial-author {
  border-top: 2px solid var(--con-light);
  padding-top: 1rem;
}

.con-testimonial-author h4 {
  color: var(--con-primary);
  margin-bottom: 0.25rem;
  font-size: 1.1rem;
}

.con-testimonial-author p {
  color: var(--con-text);
  font-size: 0.9rem;
  opacity: 0.8;
}

/* Update Responsive Design */
@media (max-width: 768px) {
  .con-services-grid,
  .con-testimonials-grid {
    grid-template-columns: 1fr;
  }

  .con-testimonial-card {
    padding: 1.5rem;
  }
}

/* Add testimonials to animations */
.con-hero-content,
.con-service-card,
.con-testimonial-card,
.con-contact-content {
  animation: fadeIn 1s ease-out;
} 