@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');


#forbidden-app {
    padding: 1rem;
    background: black;
    display: flex;
    height: 95vh;
    justify-content: center;
    align-items: center;
    color: #54FE55; 
    text-shadow: 0 0 10px #1a4f1a; 
    font-size: 6rem;
    flex-direction: column;
    font-family: 'Press Start 2P', cursive;
}

.forbidden-txt {
    font-size: 1.8rem;
}

@keyframes blink {
    0%   {opacity: 0}
    49%  {opacity: 0}
    50%  {opacity: 1}
    100% {opacity: 1}
}

.forbidden-blink {
    animation-name: blink;
    animation-duration: 1s;
   animation-iteration-count: infinite;
}

