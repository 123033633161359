.notifications-container {
    width: 320px;
    height: auto;
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .failed-flex {
    display: flex;
  }
  
  .failed-flex-shrink-0 {
    flex-shrink: 0;
  }
  
  .failed-check {
    padding: 1rem;
    border-radius: 0.375rem;
    background-color: rgb(240 253 244);
  }
  
  .failed-svg {
    color: #e82f58;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .failed-prompt-wrap {
    margin-left: 0.75rem;
  }
  
  .failed-prompt-heading {
    font-weight: bold;
    color: #e82f58;
  }
  
  .failed-prompt-prompt {
    margin-top: 0.5rem;
    color: #e82f58;
  }
  
  .failed-button-container {
    display: flex;
    margin-top: 0.875rem;
    margin-bottom: -0.375rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  
  .failed-button-main {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: #ECFDF5;
    color: #e82f58;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: bold;
    border-radius: 0.375rem;
    border: none
  }
  
  .failed-button-main:hover {
    background-color: #D1FAE5;
  }
  
  .failed-button-secondary {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.75rem;
    background-color: #ECFDF5;
    color:     #e82f58;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.375rem;
    border: none;
  }
  