.admin-new-partnership {
  width: fit-content;
  height: fit-content;
  padding: 0.7rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
  background-color: #4eb473;
  border-radius: 0.3rem;
  flex-shrink: 0;
}

.admin-new-partnership-text {
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.admin-new-partnership-icon {
  width: 0.8rem;
  height: 0.8rem;
}

.admin-partnership-table-container {
  width: 100%;
  border: 0.1rem solid #e3e3e3;
  border-radius: 0.3rem;
  height: 100%;
  padding: 0 1rem 1rem 1rem;
  overflow-x: auto !important;
}

.adminpartnershiptable {
  width: 100%;
  height: 100%;
  @media screen and (max-width: 980px) {
    width: 70rem;
  }
}

.admin-partnership-heading {
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  border-bottom: 0.1rem solid #e3e3e3;
}

.admin-table-widthA {
  padding: 0 0.5rem;
  width: 15%;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.admin-table-widthC {
  padding: 0 0.5rem;
  width: 10%;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.admin-table-widthD {
  padding: 0 0.5rem;
  width: 20%;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.admin-table-widthB {
  padding: 0 0.5rem;
  width: 25%;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.admin-table-body-widthA {
  padding: 0 0.5rem;
  width: 15%;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.admin-table-body-widthC {
  padding: 0 0.5rem;
  width: 10%;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.admin-table-body-widthD {
  padding: 0 0.5rem;
  width: 20%;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.admin-table-body-widthB {
  padding: 0 0.5rem;
  width: 25%;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.admin-partnership-body {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 0.1rem solid #e3e3e3;
}

.partnership-author-column{
    display: flex;
}

.admin-partnership-author{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}



