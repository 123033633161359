*{
    font-family: Open Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.v-menu__content--fixed {
    position: fixed !important;
}

.v-menu__content {
    position: absolute;
    display: inline-block;
    max-width: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    contain: content;
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
    border-radius: 4px;
}

.v-sheet.v-list:not(.v-sheet--outlined) {
    box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);
}
.v-sheet.v-list {
    border-radius: 0;
}
.theme--light.v-list {
    background: #fff;
    color: rgba(0,0,0,.87);
}
.v-sheet:not(.v-sheet--outlined) {
    box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);
}
.theme--light.v-sheet {
    background-color: #fff;
    border-color: #fff;
    color: rgba(0,0,0,.87);
}
.v-list {
    display: block;
    padding: 8px 0;
    position: static;
    transition: box-shadow .28s cubic-bezier(.4,0,.2,1);
}
.v-sheet {
    border-radius: 0;
}

.v-list .v-list-item--active, .v-list .v-list-item--active .v-icon {
    color: inherit;
}

.v-application a {
    color: var(--v-anchor-base);
}

.v-application a {
    cursor: pointer;
}

.v-list-item--link {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.v-list-item {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    letter-spacing: normal;
    min-height: 48px;
    outline: none;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
    justify-content: center;
   

}

.v-list-item .v-list-item__title{
    color: rgb(123, 123, 123);
    font-weight: 600;
    font-family: Open Sans, 'Source Sans Pro';
}

.v-list-item:hover{
    background-color: #4eb473;
    color: #f2f7f4;
    transition: all 0.5s ease;
}

.v-list-item:hover .v-list-item__title{
    color: #f2f7f4;
}


.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(0,0,0,.87);
}

.v-application a {
    color: var(--v-anchor-base);
}

.v-application a {
    cursor: pointer;
}

.class-inactive-menu {
    background-color: #fff!important;
    color: #000!important;
}

.v-list-item--link {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.v-list-item-link:hover{
    background-color: #4eb473;
    color: #f2f7f4;
    transition: all 0.5s ease;
}


a{  
    text-decoration: none;
}

.header-container{
    background-color: white;
    z-index: 999;
    /* padding: 0 ; */
}

.logo{
    width: 11rem;
    cursor: pointer;
}


@media screen and (max-width: 768px) {
    .logo {
      width: 5rem;
    }
  }

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.nav-icons{
    width: 2.5rem;
    cursor: pointer;
    object-fit: contain;
    vertical-align: top;
    border-radius: 0.4rem;
    @media screen and (max-width: 980px){
        width: 1.5rem;
        /* height: 1.5rem; */
      }
}

.navbar{
    display: flex;
    /* gap: 3rem; */
    justify-content: center;
    align-items: center;
}

.navbar p{
    margin: 0 3rem 0 0;
}

.navbar2{
    display: flex;
    align-items: center;
    z-index: 100;
    @media screen and (max-width: 980px){
        justify-content: flex-end;
      }
} 

.nav-icon-container{
    margin: 0 0.5rem;
}

.nav-items{
    color: rgb(123, 123, 123);
    font-size: 16px;
    font-weight: 700;
}

.nav-items.active{
    color: #2c2c2c;
}

.profile-pic{
    width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  border-radius: 50%;
  @media screen and (max-width: 980px){
    width: 1.5rem;
    height: 1.5rem;
  }
}

.cart-btn{
    position: relative;
}

.cart-number{
    width: fit-content;
  position: absolute;
  padding: 0.15rem 0.35rem;
  right: -0.5rem;
  top: 0rem;
  font-size: 0.7rem;
  font-weight: 600;
  color: #ffffff;
  font-family: Open Sans, 'Source Sans Pro';
  background-color: #4eb473;
  border-radius: 50%;
  @media screen and (max-width: 980px){
    padding: 0.1rem 0.2rem;
    font-size: 0.65;
    font-weight: 500;
  }
}

.hero-section-col-one{
    margin-bottom: 1.6rem;
    /* max-width: 31.9rem; */
    font-size: 3rem;
    font-weight: 800;
    line-height: 1.2125;
    color: #454545;
    font-family: Inter, 'Source Sans Pro';
    flex-shrink: 0;
}

.hero-section-col-text{
    /* max-width: 33.6rem; */
    font-size: 1rem;
    font-weight: 600;
    line-height: 2.6666666667;
    color: #7b7b7b;
    font-family: Open Sans, 'Source Sans Pro';
    flex-shrink: 0;
}

.hero-section-a{
   display: flex;
   justify-content: center;
   align-items: center;
   
}

.hero-sec{
    @media screen and (max-width: 768px) {
        padding: 0 2.5rem;
       }
}

.hero-input{
    padding: 0.45rem 0.418rem 0.45rem 0.85rem;
    width: 100%;
  cursor: pointer;
  border: solid 0.1rem #e3e3e3;
  background-color: #ffffff;
  border-radius: 0.4rem;
}

.hero-section-input{
    outline: none;
    border: none;
    font-size: 1.5rem;
}


.hero-section-search{
    font-size: 1rem;
    padding: 0.7rem 2rem;
    font-weight: 600;
    line-height: 1.3625;
    color: #ffffff;
    font-family: Open Sans, 'Source Sans Pro';
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
    background-color: #4eb473;
    border-radius: 0.4rem;
}

.hero-section-image{
    height: 80vh;
    width: 80%;
    @media screen and (max-width: 768px) {
        height: 50vh;
        width: 80%;
    }
    @media screen and (max-width: 980px) {
        height: 35vh;
        width: 80%;
    }
}


.about-container{
  background-color: #f2f7f4;
}

.about-heading{
    font-size: 2.15rem;
    font-weight: 800;
    line-height: 1.2125;
    color: #454545;
    font-family: Inter, 'Source Sans Pro';
}

.about-icon{
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  object-position: center;
  @media screen and (max-width:768px) {
    width: 1.7rem !important;
    height: 1.7rem !important;
  }
}

.about-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background-color: #e3e3e3;
    border-radius: 50%;
    @media screen and (max-width:768px) {
        width: 3.5rem;
        height: 3.5rem;
    }
}

.about-card-title{
    font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, 'Source Sans Pro';
}

.about-card-text{
    /* font-size: 1.5rem; */
    font-weight: 600;
    line-height: 2.1333333333;
    color: #7b7b7b;
    font-family: Open Sans, 'Source Sans Pro';
}

/* .most-search{} */


.most-search-heading{
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.2125;
    color: #454545;
    font-family: Inter, 'Source Sans Pro';
    z-index: 5;
}

.most-search-text{
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.3333333333;
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
    z-index: 5;
}

.card-search-body{
    padding: 0.8rem;
}
  
.custom-slider .slick-slide {
    padding: 0 15px 0 5px;
  }

.most-search-card{
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border-radius: 0.6rem;
    flex-shrink: 0;
    gap: 10px;
}

.search-card-heading{
    /* margin: 0rem 19.7rem 2.3rem 0rem; */
    font-size: 1rem;
    font-weight: 800;
    color: #7b7b7b;
    font-family: Open Sans, 'Source Sans Pro';
    
    /* flex-shrink: 0; */
}

.card-profile{
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-shrink: 0; */
}

.card-profile-details{
    /* margin-right: 5.5rem; */
    /* height: 100%; */
    display: flex;
    align-items: center;
    /* flex-shrink: 0; */
}

.card-profile-pic{
    margin-right: 0.4rem;
}



.card-profile-pic img{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.card-profile-name{
    font-size: 0.9rem;
  font-weight: 600;
  /* line-height: 1.3333333333; */
  color: #7b7b7b;
  font-family: Open Sans, 'Source Sans Pro';
  
  flex-shrink: 0;
  margin: auto;
}

.card-profile-status{
  text-align: right;
  font-size: 0.85rem;
  padding: 0.2rem 0.3rem;
  font-weight: 600;
  color: #a6a6a6;
  font-family: Open Sans, 'Source Sans Pro';
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 0.4rem;
  flex-shrink: 0;
}

.card-profile-status.active{
    color: #4eb473;
    background-color: #e0ffeb;
}

.card-location{
    width: 100%;
    /* height: 2.1rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.card-location-country-container{
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
}

.card-location-icon{
    margin-right: 0.5rem;
    width: 0.9rem;
}

.card-location-country{
    /* text-align: right; */
    font-size: 0.9rem;
    font-weight: 600;
    /* line-height: 1.3625; */
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
    
    flex-shrink: 0;
}
.card-location-year-container{
    /* height: 100%; */
  display: flex;
  align-items: flex-start;
}

.card-location-year-icon{
    margin-right: 0.5rem;
    width: 0.9rem;
}

.card-location-year{
    /* text-align: right; */
    font-size: 0.9rem;
    font-weight: 600;
    /* line-height: 1.3625; */
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
}

.card-article{
    /* margin: 0rem 0.2rem 2.3rem 0.2rem; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-article-b{
    /* margin-right: 23.8rem; */
    font-size: 0.9rem;
    font-weight: 600;
    /* line-height: 1.3625; */
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
    
}

.card-article-c{
    /* text-align: right; */
    font-size: 0.9rem;
    font-weight: 600;
    /* line-height: 1.3625; */
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
    
    flex-shrink: 0;
}

.card-count-container{
    width: 100% ;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-count-container-a{
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 0.2rem; */
}

.card-count{
    font-size: 1rem;
    font-weight: 800;
    color: #7b7b7b;
    font-family: Open Sans, 'Source Sans Pro';
    
    flex-shrink: 0;
}

.card-count-icon{
    width: 1.3rem;
    padding-left: 0.2rem;
}

.lens-container{
    position: relative;
    background-image: url('../../assets/images/frame-50-mWh.png');
    background-position: 70%;
    /* padding: 2rem; */
    background-size: 35%;
    background-repeat: no-repeat;
}
.download-container{
    position: relative;
    background-image: url('../../assets/images/frame-51-6yw.png');
    background-position: 70%;
    /* padding: 2rem; */
    background-size: 35%;
    background-repeat: no-repeat;
}

.lens-icon{
    width: 10rem;
    /* height: .9rem; */
    position: absolute;
    left: 45%;
    z-index: 0;
    top: -30%;
    object-fit: contain;
    vertical-align: top;
}


.find-section{
    box-sizing: border-box;
    /* padding: 4rem 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/images/rectangle-30-bg-VCm.png')

}

.find-section-title{
    font-size: 2.8rem;
    font-weight: 800;
    line-height: 1.2125;
    color: #454545;
    font-family: Inter, 'Source Sans Pro';
}

.find-section-text{
    font-size: 1rem;
    font-weight: 600;
    line-height: 2.6666666667;
    color: #7b7b7b;
    font-family: Open Sans, 'Source Sans Pro';
}

.push-text{
    display: flex;
    justify-content: center;
    /* width: 100%; */
    align-self: center;
}


.begin-search{
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    
    font-family: Open Sans, 'Source Sans Pro';
}

.begin-search-btn{
    /* width: fit-content; */
    display: flex;
    align-items: center;
    box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
    background-color: #4eb473;
    border-radius: 0.4rem;
}

.form-btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form-submit-btn{
    width: 100%;
    box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
    background-color: #4eb473;
    border-radius: 0.2rem;
    border: none;
    color: white;
    padding: 0.3rem 0;
}

.begin-search-icon{
    width: 1.7rem;
  height: 1.7rem;
}

.begin-search-container{
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-heading{
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.2125;
    color: #454545;
    font-family: Inter, 'Source Sans Pro';
    
}

.form-sub-heading{
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.3333333333;
    color: #a6a6a6;
    font-family: Open Sans, 'Source Sans Pro';
    /*  */
}

.form-name{
    padding: 0.7rem 1rem;
  width: 100%;
  /* height: 5.2rem; */
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #a6a6a6;
  /* color: #a6a6a6; */
  font-family: Open Sans, 'Source Sans Pro';
  
  background-color: #f7f7f7;
  border-radius: 0.4rem;
  border: 0;
  outline: none;
}

.form-name::placeholder{
    color: #a6a6a6;

}

.form-name:focus{
    outline: none;
    border: none;
}

.input-container{
    width: 100%;
}

.form-textarea{
    padding: 0.7rem 1rem;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #a6a6a6;
  font-family: Open Sans, 'Source Sans Pro';
  
  background-color: #f7f7f7;
  border-radius: 0.4rem;
}

.form-message{
    color: #a6a6a6;
    font-weight: 600;
}

.form-message::placeholder{
    color: #a6a6a6;
  font-weight: 600;
}

.form-textarea textarea{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: inherit;
}

.form-textarea textarea:focus{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: inherit;
}

.form-content{
    display: flex;
    flex-direction: column;
    /* column-gap: 1rem; */
    gap: 1rem;
}


.form-container{
    width: 100%;
}

.notloggedin{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: 768px){ 
        margin-right: 5%;
    }
}

.notloggedinSearch{
    margin-right: 0.8rem;
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  vertical-align: top;
  border-radius: 0.4rem;
  flex-shrink: 0;
  @media screen and (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }
}

.hamburger-icon{
    background-color: #f7f7f7;
  border-radius: 0.4rem;
  cursor: pointer;
  padding: 0.5rem 0.6rem;
  /* color: white; */
}

.signup-btn{
    margin-right: 0.8rem;
  padding: 0.55rem 0.9rem;
  cursor: pointer;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, 'Source Sans Pro';
  
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 0.4rem;
  flex-shrink: 0;
}

.login-btn{
  padding: 0.55rem 0.9rem;
    cursor: pointer;
    overflow: hidden;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.3625;
    color: #ffffff;
    font-family: Open Sans, 'Source Sans Pro';
    
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.5rem 0.4rem rgba(0, 126, 45, 0.1599999964);
    background-color: #4eb473;
    border-radius: 0.4rem;
    flex-shrink: 0;
}



.extraLink{
    color: black;
}

.extraLink:hover{
    text-decoration: underline;
}