.paymentdecline-box {
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    z-index: 100;
  }

.paymentdeclined-sub-text{
    text-align: center;
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 1.3333333333;
    color: #f66950;
    font-family: Open Sans, 'Source Sans Pro';
    white-space: nowrap;
    flex-shrink: 0;
}

.paymentdeclined-title{
    text-align: center;
    font-size: 1.8rem;
    font-weight: 800;
    line-height: 1.2125;
    color: #f66950;
    font-family: Inter, 'Source Sans Pro';
    white-space: nowrap;
    flex-shrink: 0;
}