.admin-wholebar{
    display: flex;
    width: 100%;
}

.admin-sidebar {
    width: 330px; 
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    border-right: 0.1rem solid #E0E0E0;
    /* background: #e4e9f7; */
    color: #fff;
    border-right: 0.1rem solid #E0E0E0;
    z-index: 99;
    transition: width 0.5s ease;
  }

  .admin-mainbar {
    flex-grow: 1;
    margin-left: 330px;
    padding: 1rem 0;
    min-height: 100vh;
    transition: margin-left 0.5s ease;
  }

.admin-sidebar.active{
    width: 60px;
    transition: width 0.5s ease;
}

.admin-mainbar.active{
    margin-left: 60px;
}


.admin-main-container{
  padding:0 0 0 0.5rem;
}