.login-container {
  height: 0;
  width: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.089);
  /* backdrop-filter:  blur(5px); */
  transition: height 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container.open {
  height: 100%;
  transition: height 0.4s ease;
}

.login-row {
  height: 100%;
  width: 100%;
}

.login-col-2 {
  margin-top: 30%;
  background-color: white;
  border-radius: 1rem;
}

.login-content {
  padding: 2rem;
  margin: 1rem;
  /* padding: 1.6rem 1.3rem 2.4rem 6.4rem; */
  /* width: 100%; */
  /* height: 100%; */
  /* align-items: center; */
  /* display: flex; */
  /* flex-direction: column; */
  background-color: #ffffff;
}

.login-text-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.verifywithnumber {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-account-text {
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.2125;
  color: #454545;
  font-family: Inter, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.login-text {
  margin-right: 25%;
  /* text-align: center; */
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.2125;
  color: #454545;
  font-family: Inter, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.login-cancel {
  /* margin-bottom: 0.2rem; */
  width: 2.5rem;
  height: 2.5rem;
}

.login-email-container {
  margin: 0rem 5.1rem 2rem 0rem;
  /* width: 34.8rem; */
  height: 6rem;
  /* position: relative; */
  flex-shrink: 0;
}

.login-email {
  position: relative;
  width: 100%;
  height: 3.2rem;
  border: solid 0.12rem #d8d8d8;
  background-color: #ffffff;
  border-radius: 0.4rem;
}

.login-email-text {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
}

.login-email-input {
  position: absolute;
  top: -15%;
  left: 5%;
  font-size: 0.8rem;
  padding: 0 0.2rem;
  width: fit-content;
  height: fit-content;
  /* font-weight: 600; */
  /* line-height: 0.625; */
  color: #a6a6a6;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  background-color: #ffffff;
}

.login-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 1rem;
}

.login-pass-container {
  margin: 0rem 5.1rem 0.8rem 0rem;
  /* width: 34.8rem; */
  height: 6rem;
  /* position: relative; */
  flex-shrink: 0;
}

.input__wrapper {
  position: relative;
  border: solid 0.123rem #d8d8d8;
  border-radius: 0.3rem;
  height: fit-content;
  z-index: 10;
}

.otp-wrapper {
  height: 3rem;
  @media screen and (max-width: 76px) {
    height: 2rem;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input__field {
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  color: var(--text);
  padding: 0.85rem 1rem;
  padding-right: 32px;
  background-color: white;
  font-family: Open Sans, "Source Sans Pro";
  font-weight: 600;
  /* border: 0.3rem; */
  border-radius: 0.35rem;
}

.otp-field {
  width: 100%;
  height: 100%;
  border: 0;
  /* border-bottom: 2px solid var(--secondary); */
  outline: 0;
  font-size: 16px;
  font-weight: 800;
  color: var(--text);
  /* padding: 0.85rem 1rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-right: 32px; */
  background-color: inherit;
}

.input__field::placeholder {
  color: white;
  font-size: 0.5rem;
  transition: color 0.3s ease;
}

.input__field:placeholder-shown ~ .input__label {
  font-size: 13px;
  cursor: text;
  top: 20%;
  transition: top 0.5s ease;
}

.input__field > option {
  color: #d8d8d8;
}

.input__label,
.input__field:focus ~ .input__label {
  position: absolute;
  top: -20%;
  transition: top 0.5s ease;
  display: block;
  left: 3%;
  background-color: white;
  width: fit-content;
  font-size: 0.75rem;
  color: #17b24e;
  transition: 0.3s;
  padding: 0 0.2rem 0.3rem 0.5rem;
}

label {
  color: #a7a7a7;
}

/* .input__field:focus ~ .input__wrapper{
    border: solid 0.15rem #17b24e;
} */

.input__field:focus {
  color: #17b24e;
  background-color: white;
}
.input__label {
  /* display: none; */
  transition: color 0.3s ease;
  font-size: 13px;
}

/* .input__field:focus{
    border-bottom: 2px solid var(--primary);
} */

.input__field:focus .input__icon {
  color: #17b24e;
}

.forgot-password {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #a6a6a6;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: baseline;
  flex-shrink: 0;
}

.input__icon {
  position: absolute;
  right: -14px;
  bottom: -11px;
  width: 26px;
  height: 26px;
  padding: 2px;
  transform: translate(-50%, -50%);
  transform-origin: center;
  cursor: pointer;
  color: #a7a7a7;
}

.login-btn-primary {
  border: 0;
  width: 100%;
  padding: 0.7rem 0;
  cursor: pointer;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3625;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.2rem 0.4rem rgba(10, 131, 221, 0.2899999917);
  background-color: #4eb473;
  border-radius: 0.4rem;
  flex-shrink: 0;
}

.forgotten-password {
  display: flex;
  justify-content: flex-end;
}

.click-here {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #17b24e;
  font-family: Open Sans, "Source Sans Pro";
  padding-left: 0.7rem;
  cursor: pointer;
}
