.search-result-card {
  padding: 1rem;
  /* box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.25); */
  border-radius: 0.5rem;
}

.search-result-card.active{
    /* box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.25); */
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.25);

}

.search-article-heading {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.2125;
  color: #a6a6a6;
  font-family: Inter, "Source Sans Pro";
  white-space: nowrap;
}

.search-card-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #4eb473;
  font-family: Open Sans, "Source Sans Pro";
}

.search-card-profile {
  /* margin-bottom: 0.8rem; */
  width: 100%;
  /* height: 3.2rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
}
.search-card-flex {
  /* margin-right: 16.1rem; */
  height: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
  /* flex-shrink: 0; */
}

.search-card-pic-container {
  /* width: 4rem; */
  /* height: 100%; */
  position: relative;
  /* flex-shrink: 0; */
}
.search-card-pic {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.6rem;
  overflow: hidden;
  position: relative;
}
.search-card-pic-num {
  width: 0.9rem;
  height: 0.9rem;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 600;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4eb473;
  border-radius: 0.7rem;
  position: absolute;
  left: 80%;
  bottom: 10%;
}
.search-card-name {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}
.search-card-amount {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}

.search-card-info {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.35;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  text-align: justify;
}

.search-card-count {
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  /* flex-shrink: 0; */
}

.search-card-icon-container {
  /* padding: 0.2rem 0; */
  height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  /* flex-shrink: 0; */
}

.search-card-count-icon {
  width: 2rem;
  height: 1.2rem;
  object-fit: contain;
  vertical-align: top;
  /* flex-shrink: 0; */
}

.search-card-icon-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-card-star {
  display: flex;
  align-items: center;
  gap: 4px;
}

.search-card-date {
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
}

.search-card-rate {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
}

.data-verified {
  padding: 0.25rem 0.55rem;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #4eb473;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0ffeb;
  border-radius: 0.45rem;
  /* flex-shrink: 0; */
}

.article-border {
  border-right: 2px solid #e0e0e0;
  @media screen and (max-width: 980px) {
    border-right: 0;
  }
}

.search-card-professional-title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3625;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
}

.search-card-location {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-card-location-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7b7b7b;
  font-weight: 600;
  font-size: 0.9rem;
  font-family: Open Sans, "Source Sans Pro";
}

/* .search-card-menu {
  position: absolute !important;
} */

.card__menu__content {
  position: absolute;
  /* top: 100%; */
  /* left: 0; */
  display: inline-block;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 900;
}


.search-card-dropdown-item{
    display: flex;
  align-items: center;
  gap: 10px;
}