.react-player-component{
    width: 100%;
    height: 40vh;
}

.react-player-component:hover ~.play-btn-container{
    display: block !important;
}

.play-btn-container{
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: none;
}

/* .play-btn-container.active{
    display: none;
} */

.playpausebtn{
    width: 2.5rem;
    height: 2.5rem;
}