.partnership-children-dropdown {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
}

.partnership-children-dropdown.active {
  height: fit-content;
  transition: height 0.5s ease;
}

.partners-countries {
  display: flex;
  column-gap: 1.5rem;
  align-items: flex-start;
  flex-shrink: 0;
  white-space: nowrap;
  flex-wrap: nowrap;
  width: 100%;
}

.partnership-drop-content {
  background-color: #f7f7f7;
  padding: 0.8rem 2rem;
  @media screen and (max-width: 980px) {
    padding: 0.8rem;
  }
}

.partnership-item-image {
  width: 3rem;
  height: 3rem;
  @media screen and (max-width: 980px) {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.partnership-title-box {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 60%;
  @media screen and (max-width: 980px) {
    gap: 6px;
  }
}

.partnership-item-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #454545;
  width: 100%;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  @media screen and (max-width: 980px) {
    font-size: 0.83rem;
  }
}

.partnership-item-type {
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3333333333;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  @media screen and (max-width: 980px) {
    font-size: 0.83rem;
  }
}

/* .partnership-countries-container {
  overflow: hidden;
} */

.partners-countries-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.partners-countries-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide the scrollbar thumb */
}

.partners-countries-name {
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #a6a6a6;
  min-width: 7rem;
  text-align: center;
  padding: 0 0 0.6rem 0;
  position: relative;
  cursor: pointer;
}

.partners-countries-name.active {
  color: #4eb473;
}

.grab {
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}

.partners-countries-name::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  overflow: hidden;
  transition: width 0.5s;
}

/* .partnership-overflow-box {
 
} */

.partnership-overflow-box::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.partnership-overflow-box::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 5px;
}

.partnership-overflow-box::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.partners-countries-name.active::before {
  background-color: #4eb473;
  width: 100%;
  transition: width 0.5s;
}

.partnersip_input__field {
  color: #7b7b7b;
  white-space: nowrap;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  padding: 0.85rem 0.5rem;
  background-color: white;
  font-family: Open Sans, "Source Sans Pro";
  font-weight: 600;
  border-radius: 0.3rem;
}

.partnership-input-container {
  width: 85%;
}

.partnership-dropdown-content {
  overflow-x: auto;
  @media screen and (max-width: 980px) {
    width: 50rem;
  }
}

.partnership-content-flex {
  display: flex;
  /* align-items: center; */
  column-gap: 1rem;
}

.column-large-6 {
  width: 20rem;
}

.column-large-3 {
  width: 10rem;
  align-items: center;
}

.partnership-remove-self {
  color: #f55f44;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.3333333333;
}

.partnership-pending {
  height: fit-content;
  white-space: nowrap;
  font-size: 0.8rem;
  padding: 0.2rem 0.3rem;
  color: #ffcf00;
  background-color: rgba(253, 216, 53, 0.150000006);
  border-radius: 0.4rem;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-weight: 600;
}

.partnership-accepted-container {
  display: flex;
  flex-shrink: 0;
  column-gap: 1rem;
}
.partnership-accepted-remove {
  width: 0.8rem;
  height: 0.8rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.partnership-accepted {
  justify-content: center;
  height: fit-content;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 600;
  color: #4eb473;
  background-color: #f2f7f4;
  border-radius: 0.3rem;
  flex-shrink: 0;
  padding: 0.2rem 0.3rem;
}

.partnership-declined {
  background-color: #ffe1e1;
  color: #f55f44;
  justify-content: center;
  height: fit-content;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 0.3rem;
  flex-shrink: 0;
  padding: 0.2rem 0.3rem;
}

.add-another-author {
  flex-shrink: 0;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #4eb473;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  width: fit-content;
}

.partnership-save-btn {
  justify-content: center;
  padding: 0.8rem 2rem;
  box-shadow: 0 1rem 0.8rem rgba(0, 126, 45, 0.1599999964);
  border-radius: 0.3rem;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.9rem;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  background-color: #4eb473;
  flex-shrink: 0;
}
