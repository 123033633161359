.upload-table-box {
  background-color: #f7f7f7;
}

.periodicity-sub-heading {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #454545;
  font-family: Open Sans, "Source Sans Pro";
}

.periodicity-time {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #4eb473;
  font-family: Open Sans, "Source Sans Pro";
}

.periodicity-display {
  display: flex;
  gap: 5px;
}

.upload-data-table-container {
  width: 100%;
  border-radius: 0.35rem;
  background: white;
  overflow-x: auto;
  padding: 0.4rem 0.2rem;
}

.upload-data-table-content {
  min-width: 90rem;
}

.upload-data-table-container::-webkit-scrollbar {
  /* width: 10px; */
  height: 5px;
  scroll-behavior: smooth;
}

.upload-data-table-container::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 5px;
}

.upload-data-table-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.upload-table-headings {
  display: flex;
  padding: 0.8rem 0.4rem;
  align-items: center;
  font-size: 0.95rem;
}




.upload-table-body {
  display: flex;
  padding: 1rem 0.4rem;
  align-items: center;
  /* border-top: 1px solid #7b7b7b2c; */
  min-width: 150rem;
}
.upload-table-body-items {
  flex-grow: 1;
  font-size: 0.9rem;
  font-weight: 600;
  border-top: 1px solid #7b7b7b2c !important;
  /* line-height: 1.3625; */
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
  padding: 0 2rem 0 0;
  position: relative;
  height: 100%;
}
.upload-table-body-content {
  font-size: 0.9rem;
  font-weight: 600;
  border-top: 1px solid #7b7b7b2c !important;
  /* line-height: 1.3625; */
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  padding: 0 2rem 0 0;
  position: relative;
  height: 100%;
}

.upload-table-col-2 {
  width: 15rem;
}

.upload-table-col-1 {
  width: 10rem;
}

.upload-select {
  font-size: 0.7rem !important;
}

.table-keywords-container {
  width: 100%;
  overflow-x: auto;
  border: solid 0.15rem #d8d8d8;
  background-color: #f7f7f7;
  border-radius: 0.4rem;
  display: flex;
  gap: 5px;
  padding: 0.5rem 0.8rem;
}

.table-keywords-container::-webkit-scrollbar {
  height: 5px;
}

.table-keywords-container::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 5px;
}

.table-keywords-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.table-key-word-box {
  padding: 0.5rem;
  display: flex;
  gap: 7px;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 2.5rem;
}

.table-key-word {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1725;
  color: #7b7b7b;
  font-family: Roboto, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.table-key-word-cancel {
  width: 0.6rem;
  height: 0.6rem;
}

.keyword-label {
  position: absolute;
  top: -20%;
  left: 10%;
  z-index: 100;
  font-size: 0.6rem;
  font-weight: 600;
  line-height: 1.3625;
  color: #a6a6a6;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
}

.periodicity-check {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #4eb473;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: baseline;
}

.periodicity-span {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
}
.periodicity-span-check {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #4eb473;
  font-family: Open Sans, "Source Sans Pro";
}

.table-upload-delete-btn {
  cursor: pointer;
  width: fit-content;
  padding: 0.8rem 4rem;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.2rem 0.4rem rgba(23, 178, 78, 0.2300000042);
  background-color: #4eb473;
  border-radius: 0.3rem;
}

.data-approval-checkbox-box {
  margin-top: 1rem;
  display: flex;
  gap: 10px;
  align-items: center;
}

.data-approval-checkbox {
  /* width: 0.5rem;
  height: 0.5rem;
  color: #7b7b7b; */
  display: flex;
  align-items: center;
}

.data-approval-checkbox-text {
  font-size: 0.9rem;
  font-weight: 600;
  color: #7b7b7b;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.upload-terms-and-condition {
  color: #4eb473;
}

.upload-table-validate {
  cursor: pointer;
  overflow: hidden;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3333333333;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.2rem 0.4rem rgba(23, 178, 78, 0.2300000042);
  background-color: #4eb473;
  border-radius: 0.3rem;
  flex-shrink: 0;
  width: 100%;
  padding: 0.8rem 0;
}

.padding-left {
  width: 90% !important;
  overflow-x: auto !important;
  /* word-wrap: break-word;
  flex-shrink: 1; */
}


.popup-column-body{
  position: absolute;
  top: 50%;
  /* transform: translateX(-50%, 50%); */
  z-index: 100;
  /* backdrop-filter: blur(5px); */
  word-wrap: normal;
  max-width: 60%;
  white-space: normal !important;
  background-color: whitesmoke;
  padding: 1rem;
  border-radius: 0.4rem;
}





.error-column{
  border: 2px solid red !important;
}

.error-row {
  border: 2px solid red !important;
}