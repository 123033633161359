@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

:root {
  --font-primary: "DM Sans", sans-serif;
  --text-6: 6px;
  --text-9: 9px;
  --text-11: 11px;
  --text-13: 13px;
  --text-14: 14px;
  --text-15: 15px;
  --text-16: 16px;
  --text-17: 17px;
  --text-18: 18px;
  --text-20: 20px;
  --text-24: 24px;
  --text-30: 30px;
  --text-35: 35px;
  --text-40: 40px;
  --text-45: 45px;
  --text-50: 50px;
  --text-55: 55px;
  --text-60: 60px;
  --text-64: 64px;
}

:root {
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-dark-1: #140342;
  --color-dark-2: #1A064F;
  --color-dark-3: #6A7A99;
  --color-dark-4: #242239;
  --color-dark-5: #282664;
  --color-dark-6: #311F61;
  --color-dark-7: #EAE9EF;
  --color-dark-8: #202124;
  --color-light-1: #4F547B;
  --color-light-2: #E4E7EA;
  --color-light-3: #EEF2F6;
  --color-light-4: #F7F8FB;
  --color-light-5: #EDEDED;
  --color-light-6: #F5F7FE;
  --color-light-7: #E5F0FD;
  --color-light-8: #DDDDDD;
  --color-light-9: #EEF2F6;
  --color-light-10: #F4F3F6;
  --color-light-11: #EBEAFE;
  --color-light-12: #CCE0F8;
  --color-purple-1: #00C000;
  --color-purple-2: #E3EDFD;
  --color-purple-3: #F4F1FE;
  --color-purple-4: #7545F0;
  --color-purple-5: #EAE3FD;
  --color-green-1: #00FF84;
  --color-green-2: #DEF5F0;
  --color-green-3: #EEF8F5;
  --color-green-4: #04D697;
  --color-green-5: #34A853;
  --color-green-6: #DFF1DD;
  --color-green-7: #D9FFED;
  --color-orange-1: #E8543E;
  --color-orange-2: #F7E9E7;
  --color-orange-3: #FDF2EB;
  --color-orange-4: #E97D38;
  --color-orange-5: #FDEEEC;
  --color-orange-6: #E78E34;
  --color-orange-7: #FBEEE1;
  --color-red-1: #F01E00;
  --color-red-2: #FCE5E2;
  --color-red-3: #D93025;
  --color-beige-1: #FEFBF4;
  --color-blue-1: #65C2F9;
  --color-blue-2: #EFF9FE;
  --color-blue-3: #1967D2;
  --color-blue-4: #508EF0;
  --color-blue-5: #445B95;
  --color-blue-6: #E5EEFD;
  --color-blue-7: #E3E6EF;
  --color-yellow-1: #E59819;
  --color-yellow-2: #FDF8EB;
  --color-yellow-3: #ECB53E;
  --color-yellow-4: #F9AB00;
  --color-yellow-5: #FCEDCD;
  --color-info-1: #CDE9F6;
  --color-info-2: #4780AA;
  --color-warning-1: #F7F3D7;
  --color-warning-2: #927238;
  --color-error-1: #ECC8C5;
  --color-error-2: #AB3331;
  --color-success-1: #DEF2D7;
  --color-success-2: #5B7052;
  --white:#fff
}




.lh-1 {
    line-height: 1 !important;
  }
  
  .lh-11 {
    line-height: 1.1 !important;
  }
  
  .lh-12 {
    line-height: 1.2 !important;
  }
  
  .lh-13 {
    line-height: 1.3 !important;
  }
  
  .lh-14 {
    line-height: 1.4 !important;
  }
  
  .lh-15 {
    line-height: 1.5 !important;
  }
  
  .lh-16 {
    line-height: 1.6 !important;
  }
  
  .lh-17 {
    line-height: 1.7 !important;
  }
  
  .lh-18 {
    line-height: 1.8 !important;
  }
  
  .lh-19 {
    line-height: 1.9 !important;
  }
  


.text-6 {
    font-size: var(--text-6) !important;
  }
  
  .text-9 {
    font-size: var(--text-9) !important;
  }
  
  .text-11 {
    font-size: var(--text-11) !important;
  }
  
  .text-13 {
    font-size: var(--text-13) !important;
  }
  
  .text-14 {
    font-size: var(--text-14) !important;
  }
  
  .text-15 {
    font-size: var(--text-15) !important;
  }
  
  .text-16 {
    font-size: var(--text-16) !important;
  }
  
  .text-17 {
    font-size: var(--text-17) !important;
  }
  
  .text-18 {
    font-size: var(--text-18) !important;
  }
  
  .text-20 {
    font-size: var(--text-20) !important;
  }
  
  .text-24 {
    font-size: var(--text-24) !important;
  }
  
  .text-30 {
    font-size: var(--text-30) !important;
  }
  
  .text-35 {
    font-size: var(--text-35) !important;
  }
  
  .text-40 {
    font-size: var(--text-40) !important;
  }
  
  .text-45 {
    font-size: var(--text-45) !important;
  }
  
  .text-50 {
    font-size: var(--text-50) !important;
  }
  
  .text-55 {
    font-size: var(--text-55) !important;
  }
  
  .text-60 {
    font-size: var(--text-60) !important;
  }
  
  .text-64 {
    font-size: var(--text-64) !important;
  }
  



  .fw-500 {
    font-weight: 500 !important;
  }
  
  



/* General Layout */
/* .masthead.-type-4 {
    position: relative;
    z-index: 0;
    margin-top: 91px;
    padding-top: 102px;
  } */

  .masthead.-type-4 {
    max-width: 1800px; /* Set a wider max width */
    margin: auto; /* Ensure it remains centered */
  }
  
  
  @media (max-width: 991px) {
    .masthead.-type-4 {
      padding-top: 80px;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-4 {
      padding-top: 40px;
    }
  }
  
  .masthead.-type-4 .masthead__image img {
    max-width: unset;
    width: 52vw;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-4 .masthead__image img {
      width: 100%;
    }
  }
  
  .masthead.-type-4 .masthead__content {
    padding-bottom: 100px;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-4 .masthead__content {
      padding-bottom: 60px;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-4 .masthead__content {
      padding-bottom: 40px;
    }
  }
  
  .masthead.-type-4 .masthead__title {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 45px;
    line-height: 80px;
  }
  
  @media (min-width: 1536px) {
    .masthead.-type-4 .masthead__title {
      font-size: 54px;
      line-height: 1.3;
    }
    .masthead.-type-4 .masthead__title br {
      display: none;
    }
  }
  @media (max-width: 1399px) {
    .masthead.-type-4 .masthead__title {
      font-size: 45px;
      line-height: 1.3;
    }
    .masthead.-type-4 .masthead__title br {
      display: none;
    }
  }
  
  @media (max-width: 991px) {
    .masthead.-type-4 .masthead__title {
      font-size: 45px;
    }
    .masthead.-type-4 .masthead__title br {
      display: block;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-4 .masthead__title {
      font-size: 40px;
    }
    .masthead.-type-4 .masthead__title br {
      display: none;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-type-4 .masthead__title {
      font-size: 35px;
    }
  }
  

  .container {
    max-width: 1500px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 0;
  }
  

  .container-1500 {
    max-width: 1500px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
  }
  
  @media (max-width: 1199px) {
    .container-1500 {
      max-width: 1200px;
      
    }
  }
  
  @media (max-width: 991px) {
    .container-1500 {
      max-width: 960px;
      
    }
  }
  
  @media (max-width: 767px) {
    .container-1500 {
      max-width: 720px;
    }
  }
  
  @media (max-width: 575px) {
    .container-1500 {
      max-width: 100%;
    }
  }
  
  @media (max-width: 479px) {
    .container-1500 {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  
  .container-wide {
    padding-left: 60px;
    padding-right: 60px;
    max-width: 1500px;
    margin: 0 auto;
  }
  
  @media (max-width: 575px) {
    .container-wide {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  @media (max-width: 767px) {
    .md\:container {
      max-width: 48rem;
      padding-top: 0;
      padding-left: 0.96667rem;
      padding-right: 0.96667rem;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 575px) {
    .md\:container {
      max-width: 36rem;
    }
  }
  
  @media (max-width: 479px) {
    .md\:container {
      max-width: 100%;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
  
  @media (max-width: 575px) {
    .sm\:container {
      max-width: 38rem;
    }
  }
  
  @media (max-width: 479px) {
    .sm\:container {
      max-width: 100%;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
  
  @media (max-width: 479px) {
    .xs\:container {
      max-width: 100%;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
  

  .y-gap-50 {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  
  .y-gap-50 > * {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .md\:y-gap-50 {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .md\:y-gap-50 > * {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .sm\:y-gap-50 {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .sm\:y-gap-50 > * {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  




  .justify-center {
    justify-content: center !important;
  }
  
  .xl\:justify-center {
    justify-content: center !important;
  }

  .lg\:justify-center {
    justify-content: center !important;
  }

  .md\:justify-center {
    justify-content: center !important;
  }

  .sm\:justify-center {
    justify-content: center !important;
  }



  .text-center {
    text-align: center;
  }
  

  .items-center {
    align-items: center !important;
  }
  

  .masthead.-type-4 .masthead__content {
    padding-bottom: 100px;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-4 .masthead__content {
      padding-bottom: 60px;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-4 .masthead__content {
      padding-bottom: 40px;
    }
  }

  
  .masthead.-type-4 .masthead__title {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 45px;
    line-height: 80px;
  }
  
  @media (min-width: 1536px) {
    .masthead.-type-4 .masthead__title {
      font-size: 54px;
      line-height: 1.3;
    }
    .masthead.-type-4 .masthead__title br {
      display: none;
    }
  }
  @media (max-width: 1399px) {
    .masthead.-type-4 .masthead__title {
      font-size: 45px;
      line-height: 1.3;
    }
    .masthead.-type-4 .masthead__title br {
      display: none;
    }
  }
  
  @media (max-width: 991px) {
    .masthead.-type-4 .masthead__title {
      font-size: 45px;
    }
    .masthead.-type-4 .masthead__title br {
      display: block;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-4 .masthead__title {
      font-size: 40px;
    }
    .masthead.-type-4 .masthead__title br {
      display: none;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-type-4 .masthead__title {
      font-size: 35px;
    }
  }

  .text-purple-1 {
    color: var(--color-purple-1) !important;
  }

  .underline {
    text-decoration: underline;
  }

  .button.-outline-dark-1 {
    border: 2px solid var(--color-dark-1);
  }
  
  .button.-outline-dark-1:hover {
    background-color: var(--color-dark-1);
    border-color: transparent;
    color: white !important;
  }

  
  .masthead.-type-1 .masthead__text {
    margin-top: 13px;
    font-size: 17px;
    line-height: 36px;
    color: white;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-1 .masthead__text {
      font-size: 16px;
      line-height: 1.6;
    }
    .masthead.-type-1 .masthead__text br {
      display: none;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-type-1 .masthead__text {
      margin-top: 10px;
      font-size: 15px;
    }
  }
  

  .ml-15 {
    margin-left: 15px !important;
  }
  
  .mr-15 {
    margin-right: 15px !important;
  }
  

  .pt-15 {
    padding-top: 15px !important;
  }
  
  .xl\:pt-15 {
    padding-top: 15px !important;
  }

  .pb-15 {
    padding-bottom: 15px !important;
  }
  

  .lg\:pt-15 {
    padding-top: 15px !important;
  }

  .md\:pt-15 {
    padding-top: 15px !important;
  }

  .sm\:pt-15 {
    padding-top: 15px !important;
  }

  .py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  
  .px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  
  .pt-50 {
    padding-top: 50px !important;
  }
  
  .pb-50 {
    padding-bottom: 50px !important;
  }
  
  .pl-50 {
    padding-left: 50px !important;
  }
  
  .pr-50 {
    padding-right: 50px !important;
  }
  
  .mt-50 {
    margin-top: 50px !important;
  }
  
  .mb-50 {
    margin-bottom: 50px !important;
  }
  
  .ml-50 {
    margin-left: 50px !important;
  }
  
  .mr-50 {
    margin-right: 50px !important;
  }
  
  

  
  
  .pb-60 {
    padding-bottom: 60px !important;
  }
  
  .pl-60 {
    padding-left: 60px !important;
  }



  .xl\:pt-50 {
    padding-top: 50px !important;
  }
  .xl\:pb-50 {
    padding-bottom: 50px !important;
  }
  .xl\:pl-50 {
    padding-left: 50px !important;
  }
  .xl\:pr-50 {
    padding-right: 50px !important;
  }
  .xl\:mt-50 {
    margin-top: 50px !important;
  }
  .xl\:mb-50 {
    margin-bottom: 50px !important;
  }
  .xl\:ml-50 {
    margin-left: 50px !important;
  }
  .xl\:mr-50 {
    margin-right: 50px !important;
  }


  .mb-30 {
    margin-bottom: 30px !important;
  }
  
  .ml-30 {
    margin-left: 30px !important;
  }
  
  .mr-30 {
    margin-right: 30px !important;
  }  

  py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  
  .px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  
  .pt-30 {
    padding-top: 30px !important;
  }
  
  .pb-30 {
    padding-bottom: 30px !important;
  }
  
  .pl-30 {
    padding-left: 30px !important;
  }
  
  .pr-30 {
    padding-right: 30px !important;
  }
  
  .mt-30 {
    margin-top: 30px !important;
  }

  .pt-10 {
    padding-top: 10px !important;
  }
  

  .mt-10 {
    margin-top: 10px !important;
  }

  .pt-20 {
    padding-top: 20px !important;
  }
  
  .py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  
  .px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .pt-20 {
    padding-top: 20px !important;
  }
  
  .pb-20 {
    padding-bottom: 20px !important;
  }
  
  .pl-20 {
    padding-left: 20px !important;
  }
  
  .pr-20 {
    padding-right: 20px !important;
  }
  
  .mt-20 {
    margin-top: 20px !important;
  }
  
  .mb-20 {
    margin-bottom: 20px !important;
  }
  
  .ml-20 {
    margin-left: 20px !important;
  }
  
  .mr-20 {
    margin-right: 20px !important;
  }
  


  .xl\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xl\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xl\:pt-20 {
    padding-top: 20px !important;
  }
  .xl\:pb-20 {
    padding-bottom: 20px !important;
  }
  .xl\:pl-20 {
    padding-left: 20px !important;
  }
  .xl\:pr-20 {
    padding-right: 20px !important;
  }
  .xl\:mt-20 {
    margin-top: 20px !important;
  }
  .xl\:mb-20 {
    margin-bottom: 20px !important;
  }
  .xl\:ml-20 {
    margin-left: 20px !important;
  }
  .xl\:mr-20 {
    margin-right: 20px !important;
  }

  .pr-40 {
    padding-right: 40px !important;
  }

  .mr-10 {
    margin-right: 10px !important;
  }
  
  .ml-10 {
    margin-left: 10px !important;
  }

  .py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  
  .px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  
  .pt-10 {
    padding-top: 10px !important;
  }
  
  .pb-10 {
    padding-bottom: 10px !important;
  }
  
  .pl-10 {
    padding-left: 10px !important;
  }
  

  .pt-60 {
    padding-top: 60px !important;
  }
  
  .pb-60 {
    padding-bottom: 60px !important;
  }
  
  .pl-60 {
    padding-left: 60px !important;
  }

  .mr-8 {
    margin-right: 8px !important;
  }

  .pt-5 {
    padding-top: 5px !important;
  }
  
  .pb-5 {
    padding-bottom: 5px !important;
  }
  
  .pl-5 {
    padding-left: 5px !important;
  }
  
  .pr-5 {
    padding-right: 5px !important;
  }
  
  .mt-5 {
    margin-top: 5px !important;
  }
  
  .mb-5 {
    margin-bottom: 5px !important;
  }
  
  .ml-5 {
    margin-left: 5px !important;
  }
  
  .mr-5 {
    margin-right: 5px !important;
  }

  .py-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  
  .px-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  
  .pt-3 {
    padding-top: 3px !important;
  }
  
  .pb-3 {
    padding-bottom: 3px !important;
  }
  
  .pl-3 {
    padding-left: 3px !important;
  }
  
  .pr-3 {
    padding-right: 3px !important;
  }
  
  .mt-3 {
    margin-top: 3px !important;
  }
  
  .mb-3 {
    margin-bottom: 3px !important;
  }
  
  .ml-3 {
    margin-left: 3px !important;
  }
  
  .mr-3 {
    margin-right: 3px !important;
  }

  .pt-25 {
    padding-top: 25px !important;
  }
  
  .pb-25 {
    padding-bottom: 25px !important;
  }
  
  

  .bg-red-2 {
    background-color: var(--color-red-2);
  }
  
  





  .z--1 {
    z-index: -1;
  }
  
  .z-0 {
    z-index: 0;
  }
  
  .z-1 {
    z-index: 1;
  }
  
  .z-2 {
    z-index: 2;
  }
  
  .z-3 {
    z-index: 3;
  }
  
  .z-4 {
    z-index: 4;
  }
  
  .z-5 {
    z-index: 5;
  }
  
  
  
  
  





  .bg-green-3 {
    background-color: var(--color-green-3);
  }
  
  






  @media (max-width: 767px) {
    .md\:d-none {
      display: none !important;
    }
  }  

  .masthead__button {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  

  .pt-30 {
    padding-top: 30px !important;
  }

  .sm\:pt-30 {
    padding-top: 30px !important;
  }

  .x-gap-20 {
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .x-gap-20 > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .y-gap-20 {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  
  .y-gap-20 > * {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .sm\:x-gap-20 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .sm\:x-gap-20 > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sm\:y-gap-20 {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .sm\:y-gap-20 > * {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header.-base-sidebar .col-auto {
    padding: 0;
  }

  .x-gap-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  .x-gap-30 > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .y-gap-30 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  
  .y-gap-30 > * {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .x-gap-5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  
  .x-gap-5 > * {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }

  .x-gap-10 {
    margin-left: -5px;
    margin-right: -5px;
  }
  
  .x-gap-10 > * {
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .y-gap-10 {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  
  .y-gap-10 > * {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  





  



  .button.-md {
    padding: 12px 55px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    height: 60px;
  }

  
  .button.-purple-1 {
    background-color: var(--color-purple-1);
    border: 2px solid var(--color-purple-1);
  }
  
  .button.-purple-1:hover {
    background-color: transparent !important;
    color: var(--color-purple-1) !important;
  }
  
  .button.-outline-purple-1 {
    border: 2px solid var(--color-purple-1);
  }
  
  .button.-outline-purple-1:hover {
    background-color: var(--color-purple-1);
    border-color: transparent;
    color: white !important;
  }
  .button.-narrow {
    min-width: unset;
    padding-left: 25px;
    padding-right: 25px;
    height: 50px;
  }
  
  .button.-rounded {
    border-radius: 100px;
  }

  button.-outline-dark-1 {
    border: 2px solid var(--color-dark-1);
  }
  
  .button.-outline-dark-1:hover {
    background-color: var(--color-dark-1);
    border-color: transparent;
    color: white !important;
  }

  .button.-icon {
    min-width: 120px;
    padding: 14px 30px;
    font-size: 15px;
    height: 50px;
  }
  
  .button.-single-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .button.-narrow {
    min-width: unset;
    padding-left: 25px;
    padding-right: 25px;
    height: 50px;
  }
  
  .button.-rounded {
    border-radius: 100px;
  }
  
  .button.-underline:hover {
    text-decoration: underline;
  }
  
  .button.-white {
    background-color: var(--color-white);
    border: 2px solid var(--color-white);
  }
  
  .button.-white:hover {
    background-color: transparent !important;
    color: var(--color-white) !important;
  }
  
  .button.-outline-white {
    border: 2px solid var(--color-white);
  }
  
  .button.-outline-white:hover {
    background-color: var(--color-white);
    border-color: transparent;
    color: white !important;
  }



  html.-dark-mode .-dark-button-dark-1 {
    border-color: #140342 !important;
    background-color: #140342 !important;
  }
  
  html.-dark-mode .-dark-button-dark-1:hover {
    color: #140342 !important;
  }
  
  html.-dark-mode .form-switch .switch__slider {
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
  
  html.-dark-mode .form-switch .switch__slider::before {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  html.-dark-mode .-dark-button-dark-2 {
    border-color: #2B1C55 !important;
    background-color: #2B1C55 !important;
  }
  
  html.-dark-mode .-dark-button-white {
    border-color: white !important;
    background-color: white !important;
    color: #140342 !important;
  }  
  












  .text-white {
    color: var(--color-white) !important;
  }
  

  .text-dark-1 { 
    color: var(--color-dark-1) !important;
  }
  
  
  .layout-pt-lg {
    padding-top: 120px;
  }
  
  .layout-pb-lg {
    padding-bottom: 120px;
  }
  


  .bg-dark-2 {
    background-color: var(--color-dark-2);
  }
  

  .sectionTitle__title {
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
    color: var(--color-dark-1);
  }
  
  .sectionTitle__text {
    margin-top: 10px;
    color: var(--color-light-1);
  }
  
  .sectionTitle.-light .sectionTitle__title {
    color: white;
  }
  
  .sectionTitle.-light .sectionTitle__text {
    color: white;
  }
  

  .-stepCard-hover {
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .-stepCard-hover .stepCard__icon > *,
  .-stepCard-hover .stepCard__title,
  .-stepCard-hover .stepCard__text {
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .-stepCard-hover:hover {
    background-color: white !important;
  }
  
  .-stepCard-hover:hover .stepCard__icon > * {
    color: var(--color-dark-1) !important;
  }
  
  .-stepCard-hover:hover .stepCard__title {
    color: var(--color-dark-1) !important;
  }
  
  .-stepCard-hover:hover .stepCard__text {
    color: var(--color-light-1) !important;
  }
  
  stepCard.-type-1 {
    border-radius: 8px;
  }
  
  .stepCard.-type-1 .stepCard__content {
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 8px;
    padding: 60px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .stepCard.-type-1 .stepCard__title {
    margin-top: 37px;
    font-size: 24px;
    line-height: 27px;
    font-weight: 700;
    color: white;
  }
  
  .stepCard.-type-1 .stepCard__text {
    margin-top: 17px;
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }

  


  .coursesCard.-type-1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .coursesCard.-type-1 .coursesCard__image {
    overflow: hidden;
  }
  
  .coursesCard.-type-1 .coursesCard__image_overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: var(--color-dark-1);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .coursesCard.-type-1 .coursesCard__image img {
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .coursesCard.-type-1 .coursesCard-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #EDEDED;
  }
  .-dark-mode .coursesCard.-type-1 .coursesCard-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #353535 !important;
  }
  
  .coursesCard.-type-1 .coursesCard-footer__author {
    display: flex;
    align-items: center;
  }
  
  .coursesCard.-type-1 .coursesCard-footer__author img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  
  .coursesCard.-type-1 .coursesCard-footer__author div {
    margin-left: 10px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--color-light-1);
  }
  
  .coursesCard.-type-1 .coursesCard-footer__price {
    display: flex;
    align-items: center;
  }
  
  .coursesCard.-type-1 .coursesCard-footer__price > div:nth-child(1) {
    font-size: 15px;
    line-height: 17px;
    font-weight: 500;
    margin-right: 10px;
    color: var(--color-light-1);
    text-decoration: line-through;
  }
  
  .coursesCard.-type-1 .coursesCard-footer__price > div:nth-child(2) {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: var(--color-dark-1);
  }
  
  .coursesCard.-type-1.-hover-shadow:hover {
    box-shadow: 0px 6px 15px 0px #404F680D;
  }
  
  .coursesCard.-type-1:hover {
    color: inherit;
  }
  
  .coursesCard.-type-1:hover .coursesCard__image_overlay {
    opacity: 0.5;
  }
  
  .coursesCard.-type-1:hover .coursesCard__image img {
    transform: scale(1.12);
  }
  
  .coursesCard.-type-2 {
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .coursesCard.-type-2:hover {
    box-shadow: 0px 25px 70px 0px #01213A12;
  }
  
  .coursesCard.-type-3 {
    padding: 0 40px;
  }
  
  @media (max-width: 991px) {
    .coursesCard.-type-3 {
      padding: 0;
    }
  }
  
  .coursesCard.-type-3 .coursesCard__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    font-size: 50px;
    transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .coursesCard.-type-3 .coursesCard__icon .icon {
    transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .coursesCard.-type-3:hover .coursesCard__icon {
    background-color: var(--color-purple-1) !important;
  }
  
  .coursesCard.-type-3:hover .coursesCard__icon .icon {
    color: white !important;
  }
  
  .coursesCard.-type-4 {
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .coursesCard.-type-4 .coursesCard__image {
    position: relative;
    overflow: hidden;
  }
  
  .coursesCard.-type-4 .coursesCard__image_overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: var(--color-dark-1);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .coursesCard.-type-4 .coursesCard__image img {
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .coursesCard.-type-4:hover {
    color: inherit;
  }
  
  .coursesCard.-type-4:hover .coursesCard__image_overlay {
    opacity: 0.5;
  }
  
  .coursesCard.-type-4:hover .coursesCard__image img {
    transform: scale(1.12);
  }



  .shadow-1 {
    box-shadow: 0px 20px 30px rgba(25, 25, 46, 0.04);
  }
  
  .shadow-2 {
    box-shadow: 0px 25px 70px 0px #01213A12;
  }
  
  .shadow-3 {
    box-shadow: 0px 20px 30px 0px #19192E0A;
  }
  
  .shadow-4 {
    box-shadow: 0px 1px 4px 0px #14034212;
  }
  
  .shadow-5 {
    box-shadow: 0px 6px 15px 0px #404F680D;
  }
  
  .shadow-6 {
    box-shadow: 0px 40px 30px 0px #19192E0A;
  }
  

  

  .rounded-0 {
    border-radius: 0 !important;
  }
  
  .rounded-4 {
    border-radius: 4px !important;
  }
  
  .rounded-8 {
    border-radius: 8px !important;
  }
  
  .rounded-16 {
    border-radius: 16px !important;
  }
  
  .rounded-200 {
    border-radius: 200px !important;
  }
  
  .rounded-full {
    border-radius: 100% !important;
  }
  
  .rounded-top-8 {
    border-radius: 8px 8px 0 0 !important;
  }
  
  
  .relative {
    position: relative;
  }

  .html-overflow-hidden {
    overflow: hidden !important;
  }
  
  .html-overflow-hidden body {
    overflow-y: scroll;
  }
  
  .overflow-hidden {
    overflow: hidden !important;
  }

  .h-100 {
    height: 100% !important;
  }


  .size-70 {
    flex-shrink: 0;
    width: 70px;
    height: 70px;
  }
  







  .section-slider-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .section-slider-nav.-absolute {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  
  .section-slider-nav.-absolute.-prev {
    left: -35px;
  }
  
  .section-slider-nav.-absolute.-next {
    right: -35px;
  }
  
  @media (max-width: 575px) {
    .section-slider-nav.-absolute {
      width: 48px;
      height: 48px;
    }
    .section-slider-nav.-absolute i {
      font-size: 20px !important;
    }
    .section-slider-nav.-absolute.-prev {
      left: -10px;
    }
    .section-slider-nav.-absolute.-next {
      right: -10px;
    }
  }
  
  .section-slider-nav.-absolute-out {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  
  .section-slider-nav.-absolute-out.-prev {
    right: calc(100% + 30px);
  }
  
  .section-slider-nav.-absolute-out.-next {
    left: calc(100% + 30px);
  }
  
  @media (max-width: 1500px) {
    .section-slider-nav.-absolute-out.-prev {
      right: calc(100% + 10px);
    }
    .section-slider-nav.-absolute-out.-next {
      left: calc(100% + 10px);
    }
  }
  
  .section-slider-nav.-white {
    background-color: white;
  }
  
  .section-slider-nav.-white:hover {
    background-color: var(--color-purple-1) !important;
    color: white !important;
  }
  
  .section-slider-nav.-outline-white {
    border: 2px solid white;
  }
  
  .section-slider-nav.-outline-white:hover {
    background-color: white !important;
    color: var(--color-dark-1) !important;
  }
  
  .section-slider-nav.-outline-dark-1 {
    border: 2px solid var(--color-dark-1);
  }
  
  .section-slider-nav.-outline-dark-1:hover {
    background-color: var(--color-dark-1) !important;
    color: white !important;
  }
  
  
  .overflow-hidden {
    overflow: hidden !important;
  }
  
  .overflow-normal {
    overflow: initial !important;
  }
  
  .overflow-visible {
    overflow: visible !important;
  }

  .bg-dark-1 {
    background-color: var(--color-dark-1);
  }
  

  html.-dark-mode .-dark-bg-dark-1 {
    background-color: #140342 !important;
  }
  
  html.-dark-mode .-dark-bg-dark-2 {
    background-color: #2B1C55 !important;
  }
  
  
  



  .slider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .slider-prev,
  .slider-next {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .slider-content {
    width: 80%; /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }



  
  

  html.-dark-mode .pagination-white-dot-dark>*{
  
    background-color: #fff !important;
    opacity: 1 !important;

--swiper-pagination-color: white !important;
  }

  html.-dark-mode .pagination-white-dot-dark .swiper-pagination-bullet-active {
    
    
background-color: var(--color-purple-1) !important ;

}






  .pagination.-arrows {
  display: flex;
  color: var(--color-light-2);
  }

  .pagination.-arrows > * + * {
  margin-left: 12px;
  }

  .pagination.-arrows .pagination__item {
  background-color: currentColor;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .pagination.-arrows .pagination__item.is-active {
  background-color: var(--color-purple-1);
  }


  .pagination.-buttons {
    display: flex;
    align-items: center;
  }
  
  .pagination.-buttons > * + * {
    margin-left: 40px;
  }
  
  .pagination.-buttons .pagination__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    border-radius: 100%;
    background-color: var(--color-light-7);
    color: var(--color-purple-1);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .pagination.-buttons .pagination__button .icon {
    font-size: 12px;
  }
  
  .pagination.-buttons .pagination__button:hover {
    background-color: var(--color-purple-1);
    color: white;
  }
  
  .pagination.-buttons .pagination__count > * {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-dark-1);
  }
  
  .pagination.-buttons .pagination__count > * + * {
    margin-left: 30px;
  }
  
  .pagination.-buttons .pagination__count a::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 50%;
    width: 16px;
    height: 2px;
    transform: translateX(-50%);
    background-color: var(--color-purple-1);
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
  }
  
  .pagination.-buttons .pagination__count a.-count-is-active {
    color: var(--color-purple-1);
  }
  
  .pagination.-buttons .pagination__count a.-count-is-active::after {
    opacity: 1;
  }
  
  .pagination.-avatars {
    display: flex;
    align-items: center;
  }
  
  .pagination.-avatars .pagination__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pagination.-avatars .pagination__item::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    width: 92px;
    height: 92px;
    border: 2px solid var(--color-dark-1);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
  }
  
  .pagination.-avatars .pagination__item.is-active::before {
    opacity: 1;
  }

  

  .icon-arrow-left:before {
    content: "←";
    font-size: 20px; /* Adjust size as needed */
    color: #000; /* Arrow color */
    margin-right: 5px; /* Add space between arrow and text */
  }

  .icon-arrow-right:before {
    content: "→"; /* Unicode for right arrow */
    font-size: 20px; /* Adjust size as needed */
    color: #000; /* Arrow color */
    margin-left: 5px; /* Add space between text and arrow */
  }

  .icon-arrow-top-right:before {
    content: "→"; /* Unicode for right arrow */
    font-size: 20px; /* Adjust size as needed */
    color: #fff; /* Arrow color set to white */
    /* margin-left: 5px;  */
    transform: rotate(-45deg); /* Rotate to make the arrow point top-right */
    display: inline-block;
    transition: transform 0.3s ease; /* Optional: smooth transition effect for hover */
  }
  
  .icon-arrow-top-right:hover:before {
    transform: rotate(-45deg) scale(1.1); /* Slightly scale the icon on hover */
  }
  
  
  
  .bg-light-7 {
    background-color: var(--color-light-7);
  }
  



 
  




  .text-yellow-1 {
    color: var(--color-yellow-1) !important;
  }
  

  .d-flex {
    display: flex !important;
  }
  
  .bg-light-3 {
    background-color: var(--color-light-3);
  }
  

  .icon-star {
    content: "★"; /*Unicode for a star */
    font-size: 12px;
    color: yellow;
  }


  .absolute-full-center {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .justify-start {
    justify-content: flex-start !important;
  }
  
  .justify-end {
    justify-content: flex-end !important;
  }
  
  .justify-center {
    justify-content: center !important;
  }
  
  .justify-between {
    justify-content: space-between !important;
  }
  
  
  

  .order-1 {
    order: 1;
  }
  
  .order-2 {
    order: 2;
  }
  
  .order-3 {
    order: 3;
  }
  
  .order-4 {
    order: 4;
  }
  
  .order-5 {
    order: 5;
  }
  
  .order-6 {
    order: 6;
  }




  .size-20 {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
  
  .size-25 {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
  }
  
  .size-30 {
    flex-shrink: 0;
    width: 30px !important;
    height: 30px !important;
  }
  
  .size-35 {
    flex-shrink: 0;
    width: 35px;
    height: 35px;
  }
  
  .size-40 {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
  }
  
  



  .size-45 {
    flex-shrink: 0;
    width: 45px;
    height: 45px;
  }
  
  .size-50 {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
  }
  
  .size-60 {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
  }
  
  .size-16 {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }

    

  
  
  .featureIcon.-type-1 {
    display: flex;
  }
  
  .featureIcon.-type-1 .featureIcon__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    border-radius: 100%;
  }
  
  @media (max-width: 767px) {
    .featureIcon.-type-1 .featureIcon__icon {
      width: 60px;
      height: 60px;
    }
  }
  
  .featureIcon.-type-1 .featureIcon__icon .icon {
    font-size: 38px;
  }
  
  @media (max-width: 767px) {
    .featureIcon.-type-1 .featureIcon__icon .icon {
      font-size: 32px;
    }
  }




  .composition.-type-1 {
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-end;
    gap: 60px;
  }
  
  @media (max-width: 991px) {
    .composition.-type-1 {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 575px) {
    .composition.-type-1 {
      gap: 20px;
    }
  }
  
  .composition.-type-1 .-img-1 {
    display: flex;
    justify-content: flex-end;
  }
  
  .composition.-type-1 .-img-2 {
    grid-row: span 2;
    align-self: flex-end;
  }
  
  .composition.-type-1 .-img-3 {
    display: flex;
    justify-content: flex-end;
  }
  
  .composition.-type-1 .-el-1 {
    position: absolute;
    bottom: 337px;
    right: 170px;
    box-shadow: 0px 40px 30px 0px #19192E0A;
  }
  
  @media (max-width: 991px) {
    .composition.-type-1 .-el-1 {
      right: 40px;
    }
  }
  
  .composition.-type-1 .-el-2 {
    position: absolute;
    bottom: -40px;
    left: 0;
    box-shadow: 0px 40px 30px 0px #19192E0A;
  }
  
  @media (max-width: 991px) {
    .composition.-type-1 .-el-2 {
      left: unset;
    }
  }
  
  .composition.-type-1 .-el-3 {
    position: absolute;
    top: 154px;
    left: -20px;
    box-shadow: 0px 40px 30px 0px #19192E0A;
  }
  
  @media (max-width: 991px) {
    .composition.-type-1 .-el-3 {
      left: 40px;
    }
  }
  
  @media (max-width: 575px) {
    .composition.-type-1 .-el-3 {
      top: unset;
      bottom: -30px;
      left: 20px;
    }
  }
  
  .composition.-type-2 {
    position: relative;
    display: flex;
  }
  
  @media (max-width: 767px) {
    .composition.-type-2 {
      flex-direction: column;
    }
  }
  
  @media (max-width: 575px) {
    .composition.-type-2 > * {
      width: 100% !important;
    }
  }
  
  .composition.-type-2 .-el-1 {
    width: 345px;
  }
  
  .composition.-type-2 .-el-2 {
    position: relative;
    left: -55px;
    margin-top: 58px;
    width: 300px;
  }
  
  @media (max-width: 767px) {
    .composition.-type-2 .-el-2 {
      left: 0;
    }
  }
  
  @media (max-width: 575px) {
    .composition.-type-2 .-el-2 {
      margin-top: 20px;
    }
  }
  
  .composition.-type-3 {
    position: relative;
    padding-left: 112px;
  }
  
  @media (max-width: 575px) {
    .composition.-type-3 {
      display: flex;
      flex-direction: column;
      padding-left: 0;
    }
  }
  
  .composition.-type-3 .-el-1 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 300px;
  }
  
  @media (max-width: 575px) {
    .composition.-type-3 .-el-1 {
      position: relative;
      margin-bottom: 20px;
      width: 100%;
      transform: none;
      top: unset;
      left: unset;
    }
  }
  
  .composition.-type-4 {
    position: relative;
    padding-bottom: 72px;
  }
  
  @media (max-width: 991px) {
    .composition.-type-4 {
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 575px) {
    .composition.-type-4 {
      margin-bottom: 20px;
      padding-bottom: 0;
    }
  }
  
  .composition.-type-4 .-el-2 {
    position: absolute;
    bottom: 0;
    right: -60px;
    width: 360px;
  }
  
  @media (max-width: 1199px) {
    .composition.-type-4 .-el-2 {
      right: 50%;
      transform: translate(50%);
    }
  }
  
  @media (max-width: 575px) {
    .composition.-type-4 .-el-2 {
      margin-top: 30px;
      position: relative;
      right: unset;
      width: 100%;
      transform: none;
    }
  }
  
  .composition.-type-5 {
    position: relative;
    padding-bottom: 170px;
    display: flex;
    justify-content: flex-end;
  }
  
  @media (max-width: 1199px) {
    .composition.-type-5 {
      margin-left: 50px;
    }
  }
  
  @media (max-width: 575px) {
    .composition.-type-5 {
      padding-bottom: 0;
      margin-left: 0;
      margin-bottom: 40px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  
  .composition.-type-5 .-el-2 {
    position: absolute;
    bottom: 0;
    left: -40px;
  }
  
  @media (max-width: 575px) {
    .composition.-type-5 .-el-2 {
      position: relative;
      bottom: unset;
      left: unset;
      margin-top: 30px;
    }
  }
  
  .composition.-type-5 .-el-3 {
    position: absolute;
    top: 20px;
    left: 120px;
  }
  
  .composition.-type-5 .-el-4 {
    position: absolute;
    bottom: 300px;
    left: -40px;
  }
  
  @media (max-width: 575px) {
    .composition.-type-5 .-el-4 {
      position: relative;
      bottom: unset;
      left: unset;
      margin-top: 30px;
    }
    .composition.-type-5 .-el-4 .-w-260 {
      width: 100%;
    }
  }
  
  .composition.-type-5 .-el-5 {
    position: absolute;
    bottom: 101px;
    left: 250px;
  }
  
  .composition.-type-6 {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 30px;
  }
  
  @media (max-width: 767px) {
    .composition.-type-6 {
      grid-template-columns: 1fr;
    }
  }
  
  .composition.-type-6 > * {
    width: 280px;
  }
  
  @media (max-width: 1199px) {
    .composition.-type-6 > * {
      width: 220px;
    }
  }
  
  @media (max-width: 991px) {
    .composition.-type-6 > * {
      width: 100%;
    }
  }
  
  .composition.-type-6 .-el-2 {
    grid-row: span 2;
  }
  
  .composition.-type-7 {
    position: relative;
  }
  
  .composition.-type-7 .-el-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .composition.-type-8 {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
    align-items: center;
  }
  
  @media (max-width: 575px) {
    .composition.-type-8 {
      gap: 15px;
    }
  }
  
  .composition.-type-8 .-el-1 {
    grid-row: span 2;
  }
  
  
  
  




  .d-block {
    display: block !important;
  }
  
  .d-inline-block {
    display: inline-block !important;
  }
  
  @media (max-width: 1199px) {
    .xl\:d-none {
      display: none !important;
    }
    .xl\:d-inline-block {
      display: inline-block !important;
    }
    .xl\:d-block {
      display: block !important;
    }
    .xl\:d-flex {
      display: flex !important;
    }
  }
  
  @media (max-width: 991px) {
    .lg\:d-none {
      display: none !important;
    }
    .lg\:d-inline-block {
      display: inline-block !important;
    }
    .lg\:d-block {
      display: block !important;
    }
    .lg\:d-flex {
      display: flex !important;
    }
  }
  
  @media (max-width: 767px) {
    .md\:d-none {
      display: none !important;
    }
    .md\:d-inline-block {
      display: inline-block !important;
    }
    .md\:d-block {
      display: block !important;
    }
    .md\:d-flex {
      display: flex !important;
    }
  }
  
  @media (max-width: 575px) {
    .sm\:d-none {
      display: none !important;
    }
    .sm\:d-inline-block {
      display: inline-block !important;
    }
    .sm\:d-block {
      display: block !important;
    }
    .sm\:d-flex {
      display: flex !important;
    }
  }
  
  

  