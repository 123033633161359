:root {
    --primary-color: #2ecc71;    /* Green */
    --secondary-color: #8b5cf6;  /* Purple */
    --accent-color: #27ae60;     /* Dark Green */
    --purple-light: #ddd6fe;     /* Light Purple */
    --background-color: #f8f9ff; /* Light Background */
    --text-color: #2d3748;      /* Dark Text */
    --card-background: #ffffff;
    --brand-color: #2ecc71;
    --gradient-start: #f0fff4;   /* Light Green */
    --gradient-end: #ede9fe;     /* Light Purple */
  }
  
  body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .ai-landing-container {
    min-height: 100vh;
  }
  
  /* Navbar Styles */
  .ai-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 5%;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    position: fixed;
    width: 90%;
    z-index: 1000;
  }
  
  .ai-logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .ai-highlight {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .ai-nav-links {
    display: flex;
    gap: 2rem;
    list-style: none;
  }
  
  .ai-nav-links a {
    text-decoration: none;
    color: var(--text-color);
    font-weight: 500;
    transition: color 0.3s;
  }
  
  .ai-nav-links a:hover {
    color: var(--secondary-color);
  }
  
  /* Hero Section */
  .ai-hero {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
    padding: 2rem;
  }
  
  .ai-header-content {
    text-align: center;
    max-width: 800px;
    margin-bottom: 3rem;
  }
  
  .ai-header-content h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .ai-header-content p {
    font-size: 1.2rem;
    color: var(--text-color);
    margin-bottom: 2rem;
  }
  
  /* CTA Buttons */
  .ai-cta-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .ai-cta-button {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 25px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .ai-cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(139, 92, 246, 0.2);
  }
  
  .ai-cta-button.secondary {
    background: transparent;
    border: 2px solid var(--secondary-color);
    color: var(--secondary-color);
  }
  
  .ai-cta-button.secondary:hover {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    border-color: transparent;
    color: white;
  }
  
  /* Stats Section */
  .ai-stats {
    display: flex;
    justify-content: center;
    gap: 4rem;
    margin-top: 2rem;
  }
  
  .ai-stat-item {
    text-align: center;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(139, 92, 246, 0.1);
  }
  
  .ai-stat-item h3 {
    font-size: 2rem;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0.5rem;
  }
  
  /* Features Section */
  .ai-features {
    padding: 6rem 5%;
    background-color: var(--card-background);
  }
  
  .ai-features h2 {
    text-align: center;
    margin-bottom: 3rem;
    color: var(--text-color);
  }
  
  .ai-features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .ai-feature-card {
    padding: 2rem;
    border-radius: 12px;
    background: white;
    box-shadow: 0 4px 6px rgba(139, 92, 246, 0.1);
    transition: transform 0.3s;
    border: 1px solid var(--purple-light);
  }
  
  .ai-feature-card:hover {
    transform: translateY(-5px);
  }
  
  .ai-feature-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  /* Products Section */
  .ai-products-section {
    padding: 4rem 5%;
    background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
  }
  
  .ai-product-showcase {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .ai-product-main {
    background: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(139, 92, 246, 0.1);
    border: 1px solid rgba(46, 204, 113, 0.2);
  }
  
  .ai-product-header {
    margin-bottom: 2rem;
    text-align: left;
  }
  
  .ai-product-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .ai-product-title h3 {
    font-size: 2rem;
    color: var(--text-color);
    margin: 0;
  }
  
  .ai-product-badge {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    padding: 0.25rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .ai-product-description {
    font-size: 1.1rem;
    color: var(--text-color);
    max-width: 800px;
  }
  
  .ai-product-features-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .ai-features-column h4 {
    color: var(--secondary-color);
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid var(--purple-light);
    padding-bottom: 0.5rem;
  }
  
  .ai-features-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .ai-feature-item {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 0.75rem;
    border-radius: 12px;
    background: linear-gradient(135deg, rgba(46, 204, 113, 0.1), rgba(139, 92, 246, 0.1));
    transition: transform 0.3s ease;
  }
  
  .ai-feature-item:hover {
    transform: translateY(-2px);
  }
  
  .ai-feature-icon {
    font-size: 1.5rem;
    background: white;
    padding: 0.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .ai-feature-content {
    flex: 1;
  }
  
  .ai-feature-title {
    font-weight: 600;
    color: var(--text-color);
    display: block;
    margin-bottom: 0.25rem;
  }
  
  .ai-feature-content p {
    margin: 0;
    font-size: 0.85rem;
    color: var(--text-color);
    opacity: 0.8;
    line-height: 1.4;
  }
  
  .ai-product-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid var(--purple-light);
  }
  
  .ai-product-support {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .ai-product-support p {
    margin: 0;
    color: var(--secondary-color);
    font-weight: 500;
  }
  
  .ai-product-button {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    padding: 12px 32px;
    border: none;
    border-radius: 25px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .ai-product-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(139, 92, 246, 0.2);
  }
  
  /* Achievements Section */
  .ai-achievements {
    padding: 6rem 5%;
    background-color: var(--card-background);
    text-align: center;
  }
  
  .ai-achievement-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 3rem;
  }
  
  .ai-achievement-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(139, 92, 246, 0.1);
    transition: transform 0.3s ease;
    border: 1px solid var(--purple-light);
  }
  
  .ai-achievement-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(139, 92, 246, 0.15);
  }
  
  .ai-achievement-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, rgba(46, 204, 113, 0.1), rgba(139, 92, 246, 0.1));
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 1rem;
  }
  
  .ai-achievement-card h3 {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
  }
  
  .ai-achievement-card p {
    color: var(--text-color);
    opacity: 0.8;
  }
  
  /* Footer */
  .ai-footer {
    background: linear-gradient(135deg, var(--text-color), #1a202c);
    color: white;
    padding: 4rem 5% 2rem;
  }
  
  .ai-footer-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .ai-footer-section h4 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  
  .ai-social-links {
    display: flex;
    gap: 1rem;
  }
  
  .ai-social-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
    padding: 0.5rem;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
  }
  
  .ai-social-links a:hover {
    color: var(--primary-color);
    background: rgba(255, 255, 255, 0.2);
  }
  
  .ai-footer-bottom {
    text-align: center;
    padding-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 0.9rem;
    opacity: 0.8;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .ai-nav-links {
      display: none;
    }
  
    .ai-header-content h1 {
      font-size: 2.5rem;
    }
  
    .ai-stats {
      flex-direction: column;
      gap: 2rem;
    }
  
    .ai-cta-buttons {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  
    .ai-product-features-container {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  
    .ai-product-footer {
      flex-direction: column;
      gap: 1.5rem;
      text-align: center;
    }
  
    .ai-product-main {
      padding: 1.5rem;
    }
  
    .ai-product-title {
      flex-direction: column;
      text-align: center;
    }
  
    .ai-product-description {
      text-align: center;
    }
  
    .ai-achievement-cards {
      grid-template-columns: 1fr;
    }
  
    .ai-footer-content {
      text-align: center;
    }
  
    .ai-social-links {
      justify-content: center;
    }
  
    .ai-hero {
      padding-top: 8rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 2rem;
    }
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .ai-header-content, .ai-stats, .ai-features-grid, .ai-product-main, .ai-achievement-cards {
    animation: fadeIn 1s ease-out;
  }